import React from 'react'
import styled from 'styled-components'

import { color, above, costaText } from '../styles'

import PrimaryButton from '../elements/Button/PrimaryButton/primaryButton'

function SearchThisAreaContainer({ className, searchAreaButtonText, onClick, dataCyScreenSize }) {
  return (
    <Container className={className}>
      <PrimaryButton
        type="button"
        onClick={onClick}
        disabled={false}
        btnAriaLabel="Click me"
        label={searchAreaButtonText}
        backgroundColor={color.lightRed}
        contentColor={color.white}
        hoverColor={color.white}
        hoverContentColor={color.lightRed}
        dataCy={`search-this-area-${dataCyScreenSize}`}
      />
    </Container>
  )
}

export default SearchThisAreaContainer

const Container = styled.div`
  position: relative;
  height: auto;
  width: auto;
  z-index: 1;
  pointer-events: none;
  @media only screen and (min-width: $tablet) {
    left: 27.125em;
  }

  button {
    height: 40px;
    pointer-events: auto;
    ${costaText};

    ${above.mobile`
		  height: 56px;
	  `}
  }
`
