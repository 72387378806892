const getLocaleCode = country => {
  switch (country) {
    case 'germany':
      return 'de'
    case 'uae':
      return 'ae'
    case 'ireland':
      return 'ie'
    case 'mexico':
      return 'mx'
    case 'japan':
      return 'jp'
    case 'belgium':
      return 'be'
    case 'india':
      return 'in'
    case 'austria':
      return 'at'
    case 'spain':
      return 'es'
    case 'romania':
      return 'ro'
    case 'egypt':
      return 'eg'
    default:
      return 'uk'
  }
}

export default getLocaleCode
