import React from 'react'
import styled from 'styled-components'
import { FormLabel, Button, FormValidationMessage } from 'ui/src'
import { color } from 'ui/src/styles'
import { xxsmallBodyText } from 'ui/src/styles/typography'

export const receiptValidMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp']

interface IProps {
  id: string
  label: string
  dataCy: string
  value: File | null
  buttonTextSelect: string
  buttonTextReplace: string
  error?: string | null
  onChange: (file: File) => void
}

function ReceiptField({
  id,
  label,
  dataCy,
  value,
  buttonTextSelect,
  buttonTextReplace,
  error,
  onChange,
}: IProps): React.ReactElement {
  const ref = React.useRef<HTMLInputElement>(null)
  const [filename, setFilename] = React.useState<string>('')
  const [preview, setPreview] = React.useState<string>('')

  // react to the value change and update the preview
  React.useEffect(() => {
    if (value) {
      const fileReader = new FileReader()
      fileReader.onload = function () {
        setFilename(value.name)
        setPreview(fileReader.result as string)
      }
      fileReader.readAsDataURL(value)
    }
  }, [value])

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      onChange(file)
    }
  }

  return (
    <>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <div>
        {preview && (
          <ImagePreview
            src={preview}
            alt={filename}
            onError={() => setPreview('')}
            onClick={() => ref.current?.click()}
          />
        )}
        {filename && <Filename>{filename}</Filename>}
        <Button variant="outline" onClick={() => ref.current?.click()}>
          {value ? buttonTextReplace : buttonTextSelect}
        </Button>
      </div>
      <StyledInput
        ref={ref}
        id={id}
        name={id}
        data-cy={dataCy}
        type="file"
        accept={receiptValidMimeTypes.join(',')}
        onChange={onInputChange}
      />
      {error && <FormValidationMessage>{error}</FormValidationMessage>}
    </>
  )
}

const ImagePreview = styled.img`
  display: block;
  max-height: 120px;
  border-radius: 3px;
  margin-bottom: 15px;
  cursor: pointer;
`

const Filename = styled.div`
  margin-bottom: 15px;
  ${xxsmallBodyText}
  color: ${color.greyCore}
`

const StyledInput = styled.input`
  display: none;
`

export default ReceiptField
