import { useStaticQuery, graphql } from 'gatsby'
import { usePageContext } from 'utils/src/pageContext'

const useWebsiteSettings = locale => {
  const { locale: gatsbyLocale } = usePageContext()

  const { allContentfulWebsiteSettings } = useStaticQuery(graphql`
    query WebsiteSettingsQuery {
      allContentfulWebsiteSettings {
        nodes {
          enableSeasonalSVGs
          locale: node_locale
          logo {
            gatsbyImageData(layout: CONSTRAINED, width: 456, quality: 100, placeholder: NONE)
          }
          noindex
          logoLink {
            ...FragmentLink
          }
          ...FragmentMainNavigation
          ...FragmentFooter
          enableSocialMediaIcons
          socialMediaLink1 {
            ...FragmentLink
          }
          pageNotFoundImage {
            description
            file {
              url
              fileName
              contentType
            }
          }
          pageNotFoundMessage {
            raw
          }
          pageNotFoundTitle
          socialMediaLink1Icon {
            file {
              url
            }
          }
          socialMediaLink2 {
            ...FragmentLink
          }
          socialMediaLink2Icon {
            file {
              url
            }
          }
          enableSocialMediaIcons
          socialMediaLink3 {
            ...FragmentLink
          }
          socialMediaLink3Icon {
            file {
              url
            }
          }
          socialMediaLink4 {
            ...FragmentLink
          }
          socialMediaLink4Icon {
            file {
              url
            }
          }
          cookiePolicyBannerDescription {
            raw
          }
          mobileMenuAriaLabel
          languageSelectorTitle
          cookiePolicyBannerAccept
          cookiePolicyBannerDecline
          cookiePolicyBannerAcceptAriaLabel
          cookiePolicyBannerDeclineAriaLabel
        }
      }
    }
  `)
  return allContentfulWebsiteSettings.nodes.find(element =>
    element.locale.includes(gatsbyLocale || locale)
  )
}

export default useWebsiteSettings
