import axios from 'axios'
/* @ts-ignore-next-line */
import settingsHelper from 'utils/src/settings'
/* @ts-ignore-next-line */
import environmentsHelper from 'utils/src/environments'
import { Inputs } from './FMCGRegistrationForm'

export const uploadReceipt = (inputs: Inputs) => {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${settingsHelper.getBaseUrl(environmentsHelper.getEnvironment(), ['fmcg'])}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        // temp, will move to akamai
        Authorization: process.env.GATSBY_API_KEY,
      },
      data: {
        ...inputs,
        configurationId: process.env.GATSBY_FMCG_ID,
        responseType: 'plain',
      },
    })
      .then((data: any) => {
        resolve(data)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}
