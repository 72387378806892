/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { costaText, above } from '../../../styles'

function IconButton({ className, dataCy, ariaLabel, onClick, icon, iconAlt, disabled }) {
  return (
    <StyledButton
      className={className}
      type="button"
      aria-label={ariaLabel}
      data-cy={`${dataCy}-btn`}
      onClick={onClick}
      disabled={disabled}
    >
      <img data-cy={`${dataCy}__icon`} src={icon} alt={iconAlt} />
    </StyledButton>
  )
}

IconButton.propTypes = {
  dataCy: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  iconAlt: PropTypes.string.isRequired,
}

const StyledButton = styled.button`
  border-style: none;
  padding: 0;
  cursor: pointer;
  ${costaText};
  font-size: 16px;
  line-height: 24px;
  background: none;
  height: 28px;

  ${above.tablet`
    font-size: 18px;
    line-height: 28px;
  `}
`

export default IconButton
