const microcopyDefaults = {
  fmcgRegistrationForm: {
    heading: 'Register your details below',
    firstNameLabel: 'First name',
    firstNamePlaceholder: 'Enter first name',
    surnameLabel: 'Surname',
    surnamePlaceholder: 'Enter surname',
    emailLabel: 'Email',
    emailPlaceholder: 'Enter email',
    telephoneLabel: 'Phone number',
    telephonePlaceholder: 'Enter phone number',
    countryLabel: 'Country of residence',
    countryPlaceholder: 'Select country of residence',
    receiptLabel: 'Upload receipt',
    receiptButtonTextSelect: 'Select image',
    receiptButtonTextReplace: 'Replace image',
    termsAndConditionsCheckboxLabel: null,
    ageConfirmationCheckboxLabel: null,
    receiptValidationFileType: 'Image must be a JPEG, PNG, GIF or BMP file',
    receiptValidationMaxFileSize: 'Image must not be larger than 5MB',
    winnerPromoCongratulationsText: 'Congratulations!',
    errorPopupHeader: '',
    errorPopupText: '',
    errorPopupButtonText: '',
    errorPopupParticipationHeader: '',
    errorPopupParticipationText: '',
  },
  validation: {
    required: 'This field is required',
    nameMinLength: 'Must be at least 3 characters long',
    nameMaxLength: 'Must not be more than 255 characters long',
    emailValid: 'Must be a valid email address',
    telephoneValid: 'Must be a valid phone number',
  },
  submitButtonText: 'Submit',
}

export default microcopyDefaults
