import axios from 'axios'
import settingsHelper from './legacy/settings'

const businessHelper = {
  sendEmail(environment, endpoint, body) {
    return new Promise((resolve, reject) => {
      const url = `${settingsHelper.getBaseUrl(environment, [
        process.env.GATSBY_BUDDY_PIPELINE,
        endpoint,
      ])}`
      axios({
        url,
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        data: body,
      })
        .then(data => {
          resolve(data)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  },
}

export default businessHelper
