/* eslint-disable no-nested-ternary */
import { createGlobalStyle } from 'styled-components'
import {
  color,
  costaText,
  above,
  headingOne,
  headingTwo,
  headingThree,
  headingFour,
  headingFive,
  headingSix,
  baseBodyText,
  costaTextBold,
} from '.'
import normalize from './normalize'
import wavyBackground from '../assets/wavy-background.png'

const GlobalStyles = createGlobalStyle`
    ${normalize}

    html {
      height: 100%;
    }

    body {
      ${baseBodyText}
      background-color: ${props => props.bgColor} !important;
      background-image: ${props =>
        props.noBackground ? 'none' : `url(${wavyBackground}) !important`}; 
      background-repeat: repeat !important;

      color: ${color.greyDarker};
      background-size: 320px 320px !important;

    }

    // hide oneTrust shield

    #ot-sdk-btn-floating.ot-floating-button { display: none; }

    // Styling Google Search drop down
    body .pac-container {
      box-shadow: none;
      border: 1px solid #d9d9d9;
      border-top: 0;
      margin-left: 2px;
      border-radius: 0 0 0 25px;
      ${costaText}
      }

    body .pac-logo:after,
    .pac-icon.pac-icon-marker {
      display: none;
    }

    body .pac-container .pac-item:hover .pac-item-query .pac-matched {
      color: ${color.white};
    }

    body .pac-container .pac-item .pac-item-query .pac-matched {
      color: ${color.greyDarker};
      font-weight: 100;
    }

    body .pac-container .pac-item:hover {
      color: ${color.white};
      background: ${color.costaRed};
    }

    body .pac-container .pac-item:hover .pac-item-query {
      color: ${color.white};
    }

    body .pac-container .pac-item,
    body .pac-container .pac-item .pac-item-query {
      color: #999999;
      padding: 4px;
      padding-left: 10px;
      font-weight: 100;
      font-size: 16px;

      ${above.tablet`
        font-size: 18px;
      `}
    }

    // End of Google Search styling

    main {
      display: block;
    }
    
    button:focus,

    input:focus,
    select:focus,
    a:focus {
        box-shadow: 0px 0px 8px 2px ${color.secondaryCore};
        outline: none;
    }

    h1 {
      ${headingOne};
    }

    h2 {
      ${headingTwo};
    }

    h3 {
      ${headingThree};
    }

    h4 {
      ${headingFour};
    }

    h5 {
      ${headingFive};
    }

    h6 {
      ${headingSix};
    }

    p {
      ${baseBodyText};
      
      strong,
      b {
        ${costaTextBold}
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    li {
      ${baseBodyText};
      list-style-type: none;
    }
`

export default GlobalStyles
