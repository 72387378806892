/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import axios from 'axios'
import resolveResponse from 'contentful-resolve-response'

import { format, set } from 'date-fns'
import settingsHelper from './settings'
import environmentsHelper from './environments'

import storeUnselected from '../assets/icons/storeUnselected.svg'
import expressUnselected from '../assets/icons/expressUnselected.svg'
import expressSelected from '../assets/icons/expressSelected.svg'
import storeSelected from '../assets/icons/storeSelected.svg'
import filters from './getFilterList'

const deg2rad = deg => deg * (Math.PI / 180)

export const storeHasHours = hours => !!Object.values(hours).filter(hour => hour).length

export const storeMarker = (storeType = '', selected = false) => {
  if (!storeType) return storeUnselected

  if (storeType.toUpperCase() === 'COSTA EXPRESS') {
    return (selected && expressSelected) || expressUnselected
  }
  return (selected && storeSelected) || storeUnselected
}

const storeFiltersForMarket = filters[process.env.GATSBY_BUDDY_PIPELINE || 'uk']

export const isServiceShowable = facility =>
  facility.active &&
  storeFiltersForMarket.storeCardFacilities.find(element => element.name === facility.name)

export const isAmenityShowable = facility =>
  facility.active &&
  storeFiltersForMarket.storeCardAmenities.find(element => element.name === facility.name)

export const addressStringMaker = storeAddress => {
  if (!storeAddress) return ''
  const addressArray = Object.keys(storeAddress).map(k => storeAddress[k])
  // eslint-disable-next-line
  const stringAddress = addressArray.map((a, i) => {
    if (a.length > 0 && i <= 4) {
      return `${a}, `
    }
  })
  return stringAddress.join('').trim().replace(/,$/, '')
}

export const getWalkingTime = time => {
  const toMinutes = 0.0166667
  const timeInMinutes = time * toMinutes
  return Math.round(timeInMinutes)
}

export const getInternationalStoreDistance = (lat1, lon1, lat2, lon2) => {
  const floatLat1 = Number.parseFloat(lat1)
  const floatLon1 = Number.parseFloat(lon1)
  const floatLat2 = Number.parseFloat(lat2)
  const floatLon2 = Number.parseFloat(lon2)

  if (
    Number.isNaN(floatLat1 + floatLon1 + floatLat2 + floatLon2) ||
    typeof (floatLat1 + floatLon1 + floatLat2 + floatLon2) !== 'number'
  ) {
    return null
  }

  const R = 6371
  const dLat = deg2rad(floatLat2 - floatLat1)
  const dLon = deg2rad(floatLon2 - floatLon1)
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
  const b =
    Math.cos(deg2rad(floatLat1)) *
    Math.cos(deg2rad(floatLat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2)
  const e = a + b
  const c = 2 * Math.atan2(Math.sqrt(e), Math.sqrt(1 - e))
  const d = R * c
  return (d / 1.609344).toFixed(2)
}

export const formatDisplayTime = (time, timeFormat) => {
  const dateTime = set(new Date(), {
    hours: time.split(':')[0],
    minutes: time.split(':')[1],
  })
  return timeFormat === '12' ? format(dateTime, 'h:mm a') : format(dateTime, 'HH:mm')
}

export const getDistance = (distance, unit = 'imperial') => {
  let storeDistance
  if (unit === 'metric') {
    const kilometres = distance * 1.60934
    storeDistance = `${kilometres.toFixed(1)} km`
  } else {
    storeDistance = `${distance.toFixed(1)} mi`
  }

  return storeDistance
}

export const convertStores = stores => {
  const storesData = []

  stores.forEach(store =>
    storesData.push({
      distance: store.fields.distance,
      storeNo8Digit: store.sys.id,
      storeNo7Digit: '',
      storeNameInternal: store.fields.storeName,
      storeNameExternal: store.fields.storeName,
      storeFacilities: [],
      storeBusinessModel: '',
      storeType: store.fields.storeType.fields.name,
      storeTypeIcon: store.fields.storeType.fields.icon.fields.file.url,
      storeTypeIconAlt: store.fields.storeType.fields.icon.fields.title,
      storeStatus: 'TRADING',
      telephone: '',
      email: '',
      storeAddress: {
        addressLine1: store.fields.storeAddress,
        addressLine2: '',
        addressLine3: '',
        city: '',
        postCode: '',
        region: '',
        country: '',
        countryCode: '',
      },
      longitude: store.fields.location.lon,
      latitude: store.fields.location.lat,
      storeOperatingHours: {
        mondayOpening: store.fields.mondayOpening || '',
        mondayClosing: store.fields.mondayClosing || '',
        tuesdayOpening: store.fields.tuesdayOpening || '',
        tuesdayClosing: store.fields.tuesdayClosing || '',
        wednesdayOpening: store.fields.wednesdayOpening || '',
        wednesdayClosing: store.fields.wednesdayClosing || '',
        thursdayOpening: store.fields.thursdayOpening || '',
        thursdayClosing: store.fields.thursdayClosing || '',
        fridayOpening: store.fields.fridayOpening || '',
        fridayClosing: store.fields.fridayClosing || '',
        saturdayOpening: store.fields.saturdayOpening || '',
        saturdayClosing: store.fields.saturdayClosing || '',
        sundayOpening: store.fields.sundayOpening || '',
        sundayClosing: store.fields.sundayClosing || '',
      },
      richText: store.fields.storeCardRichText,
      storeCardImage: store.fields.storeCardImage,
    })
  )

  return storesData
}

const getUkStoresUrl = (environment, lat, lng) => {
  const base = settingsHelper.getBaseUrl(environment, ['gsl'])
  return `${base}s?latitude=${lat}&longitude=${lng}&maxrec=50
  `
}

const getInternationalStoresUrl = (environment, country, locale, lat, lng) => {
  const base = settingsHelper.getBaseUrl(environment, [country, 'stores'], ['default', 'stores'])
  return `${base}?locale=${locale}&include=2&content_type=storeLocatorStore&limit=500&fields.location[near]=${lat},${lng}`
}

const getStoresUrl = (country, locale, lat, lng) => {
  const environment = environmentsHelper.getEnvironment()

  if (country === 'uk') {
    return getUkStoresUrl(environment, lat, lng)
  }

  return getInternationalStoresUrl(environment, country, locale, lat, lng)
}

/**
 * getStores()
 *
 * Function Arguments:
 * - data: This should be lat/lon coordinates of the users current location OR searched location using the searchbar
 *
 * Function Execution:
 * 1. GET all the respective stores from GSL
 * 2. Get the first 25 stores from this list (This is because the Distance Matrix API has a cap of 25 items per request)
 * 3. Format the lat/lon coordinates to be passed to the Distance Matrix API. (These need to be separated by `|` where there should be NO spaces in between i.e. 0.123,0.123|0.456,0.254)
 * 4. Iterate over the stores and add the duration to the stores list.
 *
 * For request params: https://developers.google.com/maps/documentation/distance-matrix/overview#DistanceMatrixRequests
 *
 */
export const getStores = async (data = {}) => {
  const country = process.env.GATSBY_BUDDY_PIPELINE || 'uk'
  const { lat, lng, locale } = data

  const url = getStoresUrl(country, locale, lat, lng)

  const storeData = await axios.get(url).then(response => {
    if (country === 'uk') {
      response.data.stores.forEach(store => {
        store.storeOperatingHours = {
          mondayOpening: store.storeOperatingHours.openMon,
          tuesdayOpening: store.storeOperatingHours.openTue,
          wednesdayOpening: store.storeOperatingHours.openWed,
          thursdayOpening: store.storeOperatingHours.openThu,
          fridayOpening: store.storeOperatingHours.openFri,
          saturdayOpening: store.storeOperatingHours.openSat,
          sundayOpening: store.storeOperatingHours.openSun,
          mondayClosing: store.storeOperatingHours.closeMon,
          tuesdayClosing: store.storeOperatingHours.closeTue,
          wednesdayClosing: store.storeOperatingHours.closeWed,
          thursdayClosing: store.storeOperatingHours.closeThu,
          fridayClosing: store.storeOperatingHours.closeFri,
          saturdayClosing: store.storeOperatingHours.closeSat,
          sundayClosing: store.storeOperatingHours.closeSun,
        }
      })

      return response.data.stores
    }
    response.data.items.forEach(store => {
      store.fields.distance = Number.parseFloat(
        getInternationalStoreDistance(
          lat,
          lng,
          store.fields.location.lat,
          store.fields.location.lon
        )
      )
    })

    return convertStores(resolveResponse(response.data))
  })

  return storeData
}
