/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import sanitize from 'sanitize-filename'
import { Link } from 'gatsby'
import usePageAssemblyPath from 'utils/src/usePageAssemblyPath'

const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE

function ContentfulLink({ link, customTitle, locale = defaultLocale, children }) {
  let linkElement
  if (link && link.internalLink) {
    const slug = usePageAssemblyPath(link.internalLink.slug, locale)
    linkElement = (
      <Link to={slug}>
        {customTitle || link.title}
        {children}
      </Link>
    )
  } else if (link && link.externalLink) {
    linkElement = (
      <a
        href={link.externalLink.replace(process.env.GATSBY_WEBSITE_URL, '/')}
        target={link.htmlTarget ? '_blank' : '_self'}
        rel="noreferrer"
      >
        {customTitle || link.title}
        {children}
      </a>
    )
  } else if (link && link.assetLink) {
    const slug = link.assetSlug
      ? `${sanitize(link.assetSlug).replace(/ /g, '-')}.pdf`
      : link.assetLink.file.fileName

    linkElement = (
      <a
        href={`${
          process.env.GATSBY_PATH_PREFIX === '/' ? '' : `/${process.env.GATSBY_PATH_PREFIX}`
        }/docs/${slug}`}
        target={link.htmlTarget ? '_blank' : '_self'}
        rel="noreferrer"
      >
        {customTitle || link.title}
        {children}
      </a>
    )
  }

  return <>{linkElement}</>
}

export default ContentfulLink
