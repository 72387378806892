export const initialAppState = {
  clear: false,
  activeFilterCount: 0,
  showSideBar: true,
  showStoreCard: false,
  showFilters: false,
  showChangeLocationButton: false,
  loading: true,
}

export function appStateReducer(state, action) {
  switch (action.type) {
    case 'clear':
      return { ...state, clear: true }
    case 'showSideBar':
      return { ...state, showSideBar: action.payload }
    case 'showStoreCard':
      return { ...state, showStoreCard: action.payload }
    case 'showChangeLocationButton':
      return { ...state, showChangeLocationButton: action.payload }
    case 'showFilters':
      return { ...state, showFilters: action.payload }
    case 'setActiveFilterCount':
      return { ...state, activeFilterCount: action.payload }
    case 'resetFilters':
      return {
        ...state,
        clear: false,
        activeFilterCount: state.activeFilterCount,
      }
    case 'setLoading':
      return { ...state, loading: action.payload }
    default:
      return state
  }
}

export default { appStateReducer, initialAppState }
