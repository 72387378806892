import React from 'react'
import styled from 'styled-components'
import { color, costaTextBold, xsmallBodyText } from './styles'

function FormValidationMessage({ children }: { children: React.ReactNode }): React.ReactElement {
  return <StyledMessage>{children}</StyledMessage>
}

const StyledMessage = styled.p`
  ${xsmallBodyText};
  ${costaTextBold};
  color: ${color.lightRed};
  margin-bottom: 0;
`

export default FormValidationMessage
