import { create, test, enforce, eager, optional } from 'vest'
// eslint-disable-next-line import/no-unresolved
import { email } from 'utils/src/validators'
import { Microcopy } from 'utils/src/microcopy'
import { Inputs } from './FMCGRegistrationForm'
import { receiptValidMimeTypes } from './ReceiptField'

const suite = create((inputs: Inputs, microcopy: Microcopy): void => {
  // fail on the first test of a field, for performance since we only show one error at a time
  eager()

  // only validate checkboxes if they are present
  optional({
    terms: !microcopy.fmcgRegistrationForm.termsAndConditionsCheckboxLabel,
    age: !microcopy.fmcgRegistrationForm.ageConfirmationCheckboxLabel,
  })

  /**
   * First name
   */

  test('firstName', microcopy.validation.required, () => {
    enforce(inputs.firstName).isNotBlank()
  })

  test('firstName', microcopy.validation.nameMinLength, () => {
    enforce(inputs.firstName).longerThanOrEquals(3)
  })

  test('firstName', microcopy.validation.nameMaxLength, () => {
    enforce(inputs.firstName).shorterThanOrEquals(255)
  })

  /**
   * Surname
   */

  test('surname', microcopy.validation.required, () => {
    enforce(inputs.surname).isNotBlank()
  })

  test('surname', microcopy.validation.nameMinLength, () => {
    enforce(inputs.surname).longerThanOrEquals(3)
  })

  test('surname', microcopy.validation.nameMaxLength, () => {
    enforce(inputs.surname).shorterThanOrEquals(255)
  })

  /**
   * Email
   */

  test('email', microcopy.validation.required, () => {
    enforce(inputs.email).isNotBlank()
  })

  test('email', microcopy.validation.emailValid, () => {
    enforce(inputs.email).matches(email)
  })

  /**
   * Telephone
   */

  test('telephone', microcopy.validation.required, () => {
    enforce(inputs.telephone).isNotBlank()
  })

  test('telephone', microcopy.validation.telephoneValid, () => {
    enforce(inputs.telephone).isNumeric()
  })

  /**
   * Country of residence
   */

  test('country', microcopy.validation.required, () => {
    enforce(inputs.country).isNotBlank()
  })

  /**
   * Receipt
   */

  test('receipt', microcopy.validation.required, () => {
    enforce(inputs.receipt).isNotBlank()
  })

  test('receipt', microcopy.fmcgRegistrationForm.receiptValidationFileType, () => {
    enforce(inputs.receipt).condition((file: File) => receiptValidMimeTypes.includes(file.type))
  })

  test('receipt', microcopy.fmcgRegistrationForm.receiptValidationMaxFileSize, () => {
    enforce(inputs.receipt).condition((file: File) => file.size <= 5 * 1024 * 1024)
  })

  // TODO validate image

  /**
   * Terms
   */

  test('terms', microcopy.validation.required, () => {
    enforce(inputs.terms).isTruthy()
  })

  /**
   * Age
   */

  test('age', microcopy.validation.required, () => {
    enforce(inputs.age).isTruthy()
  })
})

export default suite
