import settingsPublish from './config/settings.config.json'

const settingsHelper = {
  getBaseUrl(environment = 'localhost', apiPaths = ['gsl']) {
    let environmentName = environment
    let url = settingsPublish.api

    if (window.location.href.match(/costa\.co\.uk/)) {
      environmentName = 'prod'
    }

    for (let i = 0; i < apiPaths.length; i += 1) {
      url = url[apiPaths[i]]
    }

    let result = url[environmentName]
    if (result === undefined) {
      result = url.uat
    }
    return result
  },

  getBearerToken(environment = 'localhost') {
    return settingsPublish.bearer[environment]
  },
}

export default settingsHelper
