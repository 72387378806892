import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { above } from '../styles'

function StoreMarker({ storeType, alt, onClick, active, type, key }) {
  return (
    <Wrapper role="button" onClick={onClick} onKeyDown={onClick} active={active}>
      <Icon
        src={storeType}
        alt={alt}
        active={active}
        data-cy="store-marker"
        data-cy-type={type.split(' ').join('-').toLowerCase()}
        data-cy-id={key}
        data-cy-active={active ? 'true' : 'false'}
      />
      {active && <Shadow />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  transform: translate(-50%, -100%);
  cursor: pointer;

  width: 25px;
  height: 28px;

  ${({ active }) =>
    active &&
    `
    width: 41px;
    height: 48px;
    z-index: 1;
  `}

  ${above.tablet`
    width: 36px;
    height: 40px;

    ${({ active }) =>
      active &&
      `
      width: 65px;
      height: 77px;
      z-index: 2;
    `}
  `}
`

const Shadow = styled.div`
  pointer-events: none;
  background: black;
  opacity: 0.25;
  width: 36px;
  height: 5px;
  margin: auto;
  border-radius: 100%;

  ${above.tablet`
    width: 41px;
  `}
`

const Icon = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`

StoreMarker.propTypes = {
  storeType: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
}

export default StoreMarker
