import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { above, color, costaTextBold } from 'ui/src/styles'
import LoadingSpinner from 'ui/src/MapLoadingSpinner/mapLoadingSpinner'
import tick from '../../resources/generic/tick.png'

const makeLink = (link, external) => () => {
  const boolExternal = external === true || external === 'true'
  window.open(link, (boolExternal && '_blank') || '_self')
}

function ButtonCTA({
  btnType,
  value,
  icon,
  customStyles,
  onClick,
  disabled,
  link,
  loading,
  iconBack,
  success,
  external,
  type,
  name,
  dataCy,
}) {
  const parsedIcon = icon === true || icon === 'true'
  const parsedIconBack = iconBack === true || iconBack === 'true'
  const parsedDisabled = disabled === true || disabled === 'true'

  return (
    // eslint-disable-next-line react/button-has-type
    <StyledButtonCTA
      data-cy={`${dataCy || value.toLowerCase().replace(/ /g, '')}__CTA`}
      name={name}
      className={`button ${[btnType] || ''} ${
        parsedIcon && !value ? 'iconNoMargin' : ''
      } ${customStyles} ${parsedDisabled === true ? 'disabled' : ''} ${
        loading === true ? 'loading' : ''
      } ${success ? 'success' : ''}`}
      type={type}
      onClick={(link && makeLink(link, external)) || onClick}
      aria-disabled={parsedDisabled || null}
      disabled={parsedDisabled || null}
    >
      {parsedIconBack && <div className="iconBack" />}
      <span className={` ${loading === true || success === true ? 'hide' : 'show'} `}>{value}</span>
      <div
        className={`${parsedIcon ? 'icon' : ''} ${loading === true && 'loadingIcon'} ${
          success === true ? 'successIcon' : ''
        }`}
      />
      {loading && <LoadingSpinner text="" customStyles="loadingSpinner" />}
    </StyledButtonCTA>
  )
}

export default ButtonCTA

ButtonCTA.propTypes = {
  btnType: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  customStyles: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  link: PropTypes.string,
  loading: PropTypes.bool,
  iconBack: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  success: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  external: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  name: PropTypes.string,
}

ButtonCTA.defaultProps = {
  name: '',
  btnType: 'default',
  value: '',
  icon: false,
  customStyles: '',
  onClick: () => {},
  disabled: false,
  link: '',
  loading: false,
  iconBack: false,
  success: false,
  external: false,
  type: 'button',
}

const StyledButtonCTA = styled.button`
  &.button {
    padding: 15px 20px;
    border: none;
    border-radius: 32px;
    background: ${color.costaRed};
    color: ${color.white};
    ${costaTextBold};
    font-size: 16px;
    letter-spacing: 1px;
    margin: 0 auto;
    cursor: pointer;
    display: block;
    min-height: 42px;

    ${above.tablet`
      font-size: 18px;
      padding: 18px 27px;
    `}

    &.wide {
      ${above.tablet`
        margin: 0;
        font-size: 18px;
        padding: 17px 24px;
      `}
    }

    &:active {
      background: ${color.costaRed};
    }

    &:hover {
      background: ${color.lightRed};
    }

    &:focus {
      background: ${color.lightRed};
    }

    &.link {
      color: ${color.costaRed};
      background: none;
      border: none;
      text-decoration: none;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;

      &:hover,
      &:focus {
        color: ${color.lightRed};
        .icon {
          border-left: 6px solid ${color.lightRed};
        }

        .iconBack {
          border-right: 6px solid ${color.lightRed};
        }
      }

      .icon {
        border-left: 6px solid ${color.costaRed};
      }

      .iconBack {
        border-right: 6px solid ${color.lightRed};
      }
    }

    &.loading {
      background: ${color.lightRed};
    }

    .hide {
      visibility: hidden;
    }

    .show {
      visibility: visible;
    }

    .hideDisplay {
      display: none;
    }

    &.success {
      position: relative;
      transition: opacity 1s ease-in-out;

      .hide {
        + .successIcon {
          opacity: 1;
        }
      }

      .successIcon {
        position: absolute;
        background-image: url(${tick});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 18px;
        width: 26px;
        height: 20px;
        top: 16px;
        left: 50%;
        margin-left: -14px;
        transition: opacity 1s ease-in-out;
        opacity: 0;

        ${above.tablet`
          top: 20px;
        `}
      }
    }
  }

  .loadingSpinner {
    position: relative;
    top: 15px;
    transform: scale(0.5);
    margin-top: -48px;

    ${above.tablet`
      top: 0.75em;
    `}

    > div:before {
      background-color: ${color.white};
    }
  }

  .icon {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 6px solid ${color.white};
    display: inline-block;
    margin: 0 0 0 15px;

    ${above.tablet`
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 8px solid ${color.white};
    `}
  }

  .iconBack {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 6px solid ${color.white};
    display: inline-block;
    margin: 0 15px 0 0;

    ${above.tablet`
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 8px solid ${color.white};
    `}
  }

  .loadingIcon {
    border-color: ${color.lightRed};

    &.disabled {
      border-color: transparent;
    }
  }

  .iconNoMargin {
    &:after {
      margin: 0;
    }
  }

  &.disabled {
    cursor: not-allowed;
    background: ${color.greyCore};

    &:hover {
      background: ${color.greyCore};
      .loadingIcon {
        border-color: transparent;
      }
    }

    &:focus {
      background: ${color.greyCore};
    }
  }
`
