/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import styled from 'styled-components'

const StyledSpan = styled.span`
  display: inline-block;
`
const richTextRenderer = richTextBody => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
    },
    renderText: text => {
      const array = text.split('\n').reduce((children, textSegment, index) => {
        const testingText = textSegment
          .split('_')
          .flatMap((t, i) => [
            i > 0,
            process.env.GATSBY_BUDDY_PIPELINE === 'japan' ? <StyledSpan>{t}</StyledSpan> : t,
          ])

        // eslint-disable-next-line react/no-array-index-key
        return [...children, index > 0 && <br key={index} />, testingText]
      }, [])
      return array
    },

    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const contentType = node.data.target?.__typename || node.nodeType
        const entry = node.data.target || null

        switch (contentType) {
          case 'ContentfulImage':
            return (
              <img
                alt={entry.imageAsset.description || 'image'}
                src={entry.imageAsset.file.url}
                className={`inline-${entry.alignment || ''}`}
              />
            )
          default:
            // keep this for dev test:
            // return (
            //   <div>
            //     <h3>{`BLOCK - ${contentType}`}</h3>
            //     <pre>
            //       <code>{JSON.stringify(node, null, 2)}</code>
            //     </pre>
            //   </div>
            // )

            return null
        }
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        // keep this for dev test:
        // const contentType = node.data.target?.__typename || node.nodeType

        const entry = node.data.target || null

        if (entry?.file?.url) {
          return (
            <a href={entry.file.url} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          )
        }
        // keep this for dev test:
        // return (
        //   <div>
        //     <h3>{`${contentType} (${node.nodeType})`}</h3>
        //     <pre>
        //       <code>{JSON.stringify(node, null, 2)}</code>
        //     </pre>
        //     <pre>
        //       <code>{JSON.stringify(children, null, 2)}</code>
        //     </pre>
        //   </div>
        // )

        return null
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data

        const target =
          (uri.includes('http') &&
            uri.includes(process.env.GATSBY_ORIGIN) &&
            !uri.endsWith('.pdf')) ||
          uri.includes('mailto:') ||
          uri[0] === '#'
            ? '_self'
            : '_blank'

        return (
          <a href={uri} target={target} rel="noopener noreferrer">
            {children}
          </a>
        )
      },
      [INLINES.EMBEDDED_ENTRY]: node => {
        const contentType = node.data.target?.__typename || node.nodeType
        const entry = node.data.target || null

        switch (contentType) {
          case 'ContentfulImage':
            const image = (
              <img
                alt={entry.imageAsset.description || 'image'}
                src={entry.imageAsset.file.url}
                className={`inline-${entry.alignment || ''}`}
              />
            )

            if (entry.linkImageTo) {
              const uri = entry.linkImageTo
              const target =
                (uri.includes('http') && uri.includes(process.env.GATSBY_ORIGIN)) ||
                uri.includes('mailto:') ||
                uri[0] === '#'
                  ? '_self'
                  : '_blank'

              return (
                <a href={uri} target={target} rel="noopener noreferrer">
                  {image}
                </a>
              )
            }

            return image
          case 'ContentfulAnchor':
            // this is temporary until we come up with a proper richtext option
            return <a name={entry.anchorName} />
          default:
            // keep this for dev test:
            // return (
            //   <div>
            //     <h3>{`${contentType} (${node.nodeType})`}</h3>
            //     <pre>{JSON.stringify(node, null, 2)}</pre>
            //   </div>
            // )

            return null
        }
      },
    },
  }

  return renderRichText(richTextBody, options)
}

export default richTextRenderer
