import React from 'react'
import styled from 'styled-components'
import { above, color } from './styles'

interface IProps {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  children: React.ReactNode
}

function Form({ onSubmit, children }: IProps): React.ReactElement {
  return (
    <Wrapper noValidate onSubmit={onSubmit}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.form`
  background-color: ${color.white};
  padding: 28px 16px;
  margin: 0 auto;

  ${above.tablet`
    padding: 56px 42px;
    max-width: 768px;
  `}

  ${above.tabletLarge`
    padding: 66px 84px;
    max-width: 824px;
  `}

  ${above.desktop`
    padding: 66px 118px;
    max-width: 1274px;
  `}
`

export default Form
