import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import PrimaryButton from '../elements/Button/PrimaryButton/primaryButton'
import useLocationIcon from '../assets/icons/storeLocator/useYourLocation.svg'
import { color, above, costaTextBold } from '../styles'

function UserLocationButton({ iconAlt, btnAriaLabel, handleButtonClick, className, dataCy }) {
  return (
    <UseYourLocation
      className={className}
      dataCy={`${dataCy}location`}
      btnAriaLabel={btnAriaLabel}
      backgroundColor={color.costaRed}
      contentColor={color.white}
      iconDirection="left"
      icon={useLocationIcon}
      iconAlt={iconAlt}
      hoverColor={color.lightRed}
      onClick={() => {
        window._satellite && window._satellite.track('use-my-location-click')
        handleButtonClick()
      }}
      disabled={false}
    />
  )
}

const UseYourLocation = styled(PrimaryButton)`
  position: absolute;
  z-index: 1;
  height: 48px;
  width: 48px;
  padding: 10px;
  right: 0;
  bottom: 0;

  ${above.tablet`
    height: 56px;
    width: 56px;
  `}

  img {
    height: 28px;
    width: 28px;

    ${above.tablet`
	  	height: 36px;
	    width: 36px; 
	  `}
  }

  span {
    ${costaTextBold};
    margin: 14px 0px 14px 9px;
    font-size: 20px;
    line-height: 22px;

    ${above.tablet`
      margin: 18px 0px 18px 8px;
      font-size: 30px;
      line-height: 36px;
    `}
  }
`

UserLocationButton.propTypes = {
  iconAlt: PropTypes.string.isRequired,
  btnAriaLabel: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
}

export default UserLocationButton
