import { useStaticQuery, graphql } from 'gatsby'

const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE

const useStoreLocatorMap = locale => {
  locale = locale || defaultLocale
  const { allContentfulBlockStoreLocatorMap } = useStaticQuery(graphql`
    query BlockStoreLocatorMapQuery {
      allContentfulBlockStoreLocatorMap {
        nodes {
          node_locale
          mapPage {
            slug
          }
          storeTypes {
            name
            icon {
              file {
                url
              }
              title
            }
          }
          storeCardSecondButtonLink
          storeTimesFormat
        }
      }
    }
  `)
  allContentfulBlockStoreLocatorMap.nodes.forEach(node =>
    node.storeTypes.forEach(storeType => {
      storeType.facilities = []
    })
  )

  return allContentfulBlockStoreLocatorMap.nodes.find(element =>
    element.node_locale.includes(locale)
  )
}

export default useStoreLocatorMap
