/* eslint-disable import/no-extraneous-dependencies */

import React, { useState } from 'react'
import styled from 'styled-components'
import { above, color, costaTextBold, costaText } from 'ui/src/styles'
import Heading from '../Heading/heading'
import Input from '../../legacy/generic/Input/input'
import TextArea from '../../legacy/generic/TextArea/textArea'
import ButtonCTA from '../../legacy/generic/ButtonCTA/buttonCTA'
import PopupCTA from '../../legacy/generic/PopupCTA/popupCTA'
import validation from '../../helpers/legacy/validation'
import businessContactApi from '../../helpers/businessContactApi'
import environmentsHelper from '../../helpers/legacy/environments'

function BusinessContactUsFormNorway() {
  const [inputs, setInputs] = useState({
    firstName: {},
    surname: {},
    email: {},
    contactNumber: {},
    addressLn6Norway: {},
    category: {},
    description: {
      optional: true,
    },
  })
  const [loading, setLoading] = useState(false)
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)

  const categoryOptions = ['Restaurant/ Servering', 'Bedrift stor', 'Bedrift liten']

  const validateForm = () => {
    const newInputs = { ...inputs }

    let valid = true
    Object.entries(newInputs).forEach(([key, input]) => {
      const isInputValid = () => {
        let inputValid = !!input.value.match(validation[key])
        if (['firstName', 'surname'].includes(key)) {
          inputValid =
            inputValid &&
            !input.value.match(validation.containsUrl) &&
            !input.value.match(validation.longEmail)
        }
        return inputValid
      }

      input.empty = !input.value

      if (input.optional) {
        input.valid = !input.empty ? isInputValid() : true
      } else if (!input.empty) {
        input.valid = isInputValid()
      } else {
        input.valid = false
      }

      // allow valid to become false but not back to true
      valid = valid ? input.valid : false
    })

    if (!valid) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    setInputs(newInputs)

    return valid
  }

  const submitForm = async () => {
    setLoading(true)
    const environment = environmentsHelper.getEnvironment()

    const valid = validateForm()
    if (valid) {
      const body = {
        name: `${inputs.firstName.value} ${inputs.surname.value}`,
        telephone: inputs.contactNumber.value,
        email: inputs.email.value,
        postcode: inputs.addressLn6Norway.value || '',
        enquiryOptions: inputs.category.value,
        message: inputs.description.value || '',
      }

      try {
        const response = await businessContactApi.sendEmail(environment, 'contact', body)
        const success = response.status && !response.error
        if (success) {
          setShowSuccessPopup(true)
        } else {
          setShowErrorPopup(true)

          window._satellite && window._satellite.track('business-contact-submission')
        }
      } catch (error) {
        setShowErrorPopup(true)
      }
    }
    setLoading(false)
  }

  const updateElementsInState = input => {
    const newInputs = { ...inputs }

    newInputs[input.name].empty = !inputs[input.name].value
    newInputs[input.name].type = input.type
    newInputs[input.name].value = input.value

    setInputs(newInputs)
  }

  const setDescription = value => {
    const newInputs = { ...inputs }

    newInputs.description.value = value

    setInputs(newInputs)
  }

  return (
    <Wrapper>
      <StyledBusinessContactUs data-cy="norway-business-contact-form">
        <Heading
          dataCy="business-contact-form"
          text="Ta kontakt med oss"
          subtitle="Vennligst fyll ut skjemaet under for en uforpliktende prat!"
        />
        <form className="form">
          <Input
            inputs={[
              {
                placeholder: 'Legg inn fornavn',
                type: 'text',
                name: 'firstName',
                valid: inputs.firstName.valid,
                validation: updateElementsInState,
              },
            ]}
            dataCy="firstName"
            heading="Fornavn"
            description="Legg inn ditt fornavn"
            error={inputs.firstName.valid === false}
            errorMessage="Legg inn fornavn"
            customStyles="input"
          />
          <Input
            inputs={[
              {
                placeholder: 'Legg inn etternavn',
                type: 'text',
                name: 'surname',
                valid: inputs.surname.valid,
                validation: updateElementsInState,
              },
            ]}
            dataCy="surname"
            heading="Etternavn"
            description="Legg inn ditt etternavn"
            error={inputs.surname.valid === false}
            errorMessage="Legg inn etternavn"
            customStyles="input"
          />
          <Input
            inputs={[
              {
                placeholder: 'Legg inn epost',
                type: 'text',
                name: 'email',
                valid: inputs.email.valid,
                validation: updateElementsInState,
              },
            ]}
            dataCy="email"
            heading="Epost"
            description="Legg inn din epost så vi kan kontakte deg"
            error={inputs.email.valid === false}
            errorMessage="Legg inn epost"
            customStyles="input"
          />
          <Input
            inputs={[
              {
                placeholder: 'Legg inn telefonnummer',
                type: 'tel',
                name: 'contactNumber',
                valid: inputs.contactNumber.valid,
                validation: updateElementsInState,
              },
            ]}
            dataCy="contactnumber"
            heading="Telefonnummer"
            description="Legg inn ditt telefonnummer så vi kan ringe deg opp"
            error={inputs.contactNumber.valid === false}
            errorMessage="Legg inn telefonnummer"
            customStyles="input"
          />
          <Input
            inputs={[
              {
                placeholder: 'Legg inn postnummer',
                type: 'tel',
                name: 'addressLn6Norway',
                valid: inputs.addressLn6Norway.valid,
                validation: updateElementsInState,
              },
            ]}
            dataCy="postcode"
            heading="Postnummer"
            description="Legg inn ditt postnummer slik at vi vet hvilken region du tilhører"
            error={inputs.addressLn6Norway.valid === false}
            errorMessage="Legg inn postnummer"
            customStyles="input"
          />
          <Input
            inputs={[
              {
                placeholder: 'Velg kategori',
                type: 'select',
                name: 'category',
                itemList: categoryOptions,
                valid: inputs.category.valid,
                validation: updateElementsInState,
                selectedIndex: 0,
              },
            ]}
            dataCy="category"
            heading="Velg kategori"
            description="Velg den kategorien din bedrift tilhører"
            items={categoryOptions}
            customStyles="sector input"
            error={inputs.category.valid === false}
            errorMessage="Velg kategori"
          />
          <TextArea
            dataCy="description"
            description="Legg igjen en melding dersom du har en konkret beskjed til oss"
            charactersLabel="tegn"
            onChange={setDescription}
            maxLength={1000}
          />
          <ButtonCTA
            dataCy="submit"
            value="Send inn"
            icon
            customStyles="button"
            onClick={submitForm}
            loading={loading}
          />
          <PopupCTA
            buttonValue="Tilbake"
            click={() => {
              window.location = '/'
            }}
            icon
            content={
              <div>
                <header>
                  <h1>Suksess!</h1>
                </header>
                <p>Ditt skjema er registrert</p>
                <ButtonCTA
                  value="Til hovedsiden"
                  icon
                  link="/"
                  customStyles="successButton"
                  dataCy="popupsuccess"
                />
              </div>
            }
            visible={showSuccessPopup}
            type="link"
            customStyles="popupHeader"
            dataCy="backtohome"
          />
          <PopupCTA
            buttonValue="Tilbake"
            click={() => {
              setShowErrorPopup(false)
              setShowSuccessPopup(false)
            }}
            icon
            content={
              <div>
                <header>
                  <h1>Noe gikk galt!</h1>
                </header>
                <p>Vennligst prøv å fylle ut skjemaet på nytt</p>
              </div>
            }
            visible={showErrorPopup}
            customStyles="popupHeader"
            dataCy="popuperror"
          />
        </form>
      </StyledBusinessContactUs>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${color.white};
  padding: 28px 16px;
  margin: 0 auto;

  ${above.tablet`
    padding: 56px 42px;
    max-width: 768px;
  `}

  ${above.tabletLarge`
    padding: 66px 84px;
    max-width: 824px;
  `}

  ${above.desktop`
    padding: 66px 118px;
    max-width: 1274px;
  `}
`

const StyledBusinessContactUs = styled.section`
  padding-bottom: 35px;
  margin: 0 8px;

  .hidden {
    display: none;
  }

  .form {
    margin-top: 31px;

    ${above.tablet`
      max-width: 474px;
      margin: 39px auto 0;
    `}

    ${above.tabletLarge`
      margin-top: 55px;
      margin-left: 0;
    `}

    ${above.desktop`
      max-width: 448px;
      margin-top: 57px;
      margin-left: 128px;
    `}

    input {
      width: 100%;
    }

    .fullWidth {
      box-sizing: content-box;
    }

    .input {
      margin-bottom: 8px;

      ${above.tablet`
        margin-bottom: 28px;
      `}

      div {
        div {
          input {
            display: block;
          }
        }
      }
    }

    .nameField {
      display: inline-block;
      vertical-align: top;

      > div > div:nth-of-type(1) {
        width: 40%;
        vertical-align: top;

        > section {
          width: 100%;
          padding: 0;
        }
      }

      > div > div:nth-of-type(2) {
        width: 60%;
        display: inline-block;
      }

      > div > div:nth-of-type(3) {
        width: 100%;
      }
    }

    .addressFinder {
      > section:nth-of-type(1) {
        div input {
          padding: 0 20px;
        }
      }
    }

    .disclaimer {
      font-style: italic;
      padding: 0;
      margin-top: 16px;

      ${above.tablet`
        margin-top: 46px;
      `}

      ${above.tabletLarge`
        width: 684px;
      `}
    }

    .popupContainer {
      button {
        margin: 0 auto;
      }
    }

    .button {
      margin-top: 44px;

      ${above.tablet`
        margin-left: 0;
      `}
    }

    .successButton {
      margin-bottom: 20px;
    }

    .sector {
      > div > div {
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    .customLink:nth-of-type(1) {
      margin: 0 0 16px;
      padding: 0;
      text-decoration: underline;
      ${costaTextBold};
    }

    .addressFinder {
      width: 100%;
      top: 0;

      ${above.tablet`
        margin-top: 34px;
      `}

      > section:nth-of-type(1) {
        width: 96px;

        ${above.tablet`
          width: 240px;
        `}

        ${above.desktop`
          width: 208px;
        `}

        input {
          width: 100%;
        }
      }

      > button:nth-of-type(1) {
        width: auto;
        margin: 0 0 0 16px;
        padding: 6px;
        height: 48px;
        float: right;
        top: 0;

        ${above.tablet`
          margin: 0;
          position: static !important;
          width: 174px;
          height: 56px;
          letter-spacing: normal;
        `}
      }

      > button:nth-of-type(2) {
        ${costaText};
        letter-spacing: normal;
        margin-top: -8px;
        text-decoration: underline;
      }
    }
  }

  .header {
    margin: 0 auto !important;
    font-size: 30px;
    line-height: 30px;

    ${above.tablet`
      font-size: 50px;
      line-height: 50px;
      max-width: 426px;
      margin: 0 auto 17px !important;
    `}

    ${above.tabletLarge`
      font-size: 50px;
      max-width: 572px;
    `}

    ${above.desktop`
      max-width: 684px;
    `}

    + p {
      ${above.tablet`
        max-width: 426px;
        margin: auto;
      `}

      ${above.tabletLarge`
        max-width: 572px;
      `}

      ${above.desktop`
        max-width: 684px;
      `}
    }
  }

  .popupHeader {
    div {
      div {
        div {
          header {
            h1 {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
`

export default BusinessContactUsFormNorway
