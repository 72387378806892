/* eslint-disable react/no-unused-prop-types */
// eslint-disable-next-line import/no-extraneous-dependencies
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// @ts-ignore
import richTextRenderer from 'utils/src/richTextRenderer'
import { color, above, costaTextBold, costaText } from '../styles'
import accordionBackgroundColour from './accordionColours'
import accordionTitleIcon from '../assets/Accordion_Icon_Plus.svg'
import accordionTitleIconOpen from '../assets/Accordion_Icon_Close-2.svg'

interface AccordionItemProps {
  accordion?: any
  locale?: string
  accordionLength?: number
  accordionColour?: string
}

interface BlockAccordionProps {
  accordionImage: any
  anchor: string
  open: boolean
  title: string
  content: any
  accordionLength: number
  accordionItem: any
  node_locale: string
  richTextField: any
  accordionColour?: string
}

function AccordionItem({ accordion, locale, accordionColour }: AccordionItemProps) {
  const accordionImage = accordion?.accordionImage

  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  useEffect(() => {
    const openFromAnchor = window.location.hash.substring(1) === accordion.anchor
    const openByDefault = !window.location.hash && accordion.open
    if (openFromAnchor || openByDefault) {
      setIsAccordionOpen(true)
    }
  }, [accordion.anchor, accordion.open])

  const openWithEnterButton = (event: any) =>
    event.code === 'Enter' && setIsAccordionOpen(!isAccordionOpen)

  const accordionBgColour = accordionBackgroundColour(accordionColour)

  return (
    <Accordion
      className={isAccordionOpen ? 'accordionOpen' : undefined}
      key={accordion.anchor}
      tabIndex={-1}
      onClick={() => setIsAccordionOpen(!isAccordionOpen)}
      onKeyDown={openWithEnterButton}
      accordionColour={accordionBgColour}
    >
      <AccordionTitleContainer
        className={isAccordionOpen ? 'accordionOpen' : undefined}
        accordionColour={accordionBgColour}
      >
        <AccordionTitle
          role="button"
          tabIndex={0}
          onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          aria-controls={accordion.anchor}
          aria-expanded={isAccordionOpen}
          id={accordion.anchor}
        >
          {accordion.title}
          <AccordionTitleIcon
            className={isAccordionOpen ? 'accordionOpen' : undefined}
            src={isAccordionOpen ? accordionTitleIconOpen : accordionTitleIcon}
            alt="accordionToggleIcon"
          />
        </AccordionTitle>
      </AccordionTitleContainer>

      <AccordionContent
        className={isAccordionOpen ? 'accordionOpen' : undefined}
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        accordionColour={accordionBgColour}
      >
        {accordionImage && (
          <ImageWrapper>
            <GatsbyImage image={accordionImage.gatsbyImageData} alt="" />
          </ImageWrapper>
        )}
        <AccordionRichTextContainer>
          {richTextRenderer(accordion.content, locale)}
        </AccordionRichTextContainer>
      </AccordionContent>
    </Accordion>
  )
}

function AccordionBlock({
  title,
  anchor,
  accordionItem,
  node_locale,
  richTextField,
  accordionColour,
}: BlockAccordionProps) {
  return (
    <Container>
      <AccordionAccordionContentWrapper>
        {title && <Title id={anchor}>{title}</Title>}
        {richTextField && (
          <AccordionAccordionBlockContent>
            <AccordionRichTextContainer>
              {richTextRenderer(richTextField, node_locale)}
            </AccordionRichTextContainer>
          </AccordionAccordionBlockContent>
        )}
        {accordionItem &&
          accordionItem.map((accordion: any, index: React.Key | null | undefined) => (
            <AccordionItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              accordion={accordion}
              accordionLength={accordionItem.length}
              // eslint-disable-next-line camelcase
              locale={node_locale}
              accordionColour={accordionColour}
            />
          ))}
      </AccordionAccordionContentWrapper>
    </Container>
  )
}

const Container = styled.article`
  background: ${color.white};
  padding: 32px 16px;

  ${(above as any).tablet`
    margin: 0px 10%;
    padding: 0;
  `}
`

const Accordion = styled('div')<AccordionItemProps>`
  margin: 0 auto 16px auto;

  &.accordionOpen {
    margin: 0 auto;
  }
`

const AccordionTitleContainer = styled('div')<AccordionItemProps>`
  border-radius: 8px;
  background: ${p => p.accordionColour};
`

const AccordionTitle = styled('label')<AccordionItemProps>`
  color: ${color.costaRed};
  ${costaTextBold}
  font-size: 18px;
  line-height: 1.5;
  display: block;
  position: relative;
  padding: 16px 48px 16px 16px;
  cursor: pointer;
  user-select: none;
  margin: 0 auto;

  ${(above as any).tablet`
    font-size: 24px;
    line-height: 1.2;
    padding: 16px 80px 16px 16px;
  `}

  ${(above as any).desktop`
    padding-top: 16px;
  `}

  &:hover {
    transition: 0.3s;
  }
`

const AccordionTitleIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 20px;
  transition: transform 0.3s;
  transform: rotate(90deg);
  &.accordionOpen {
    transform: rotate(180deg);
  }

  &:hover {
    transition: 0.3s;
  }

  ${(above as any).tablet`
    top: 24px;
    right: 16px;
  `}

  ${(above as any).desktop`
    top: 24px;
    right: 24px;
  `}
`

const AccordionContent = styled('div')<AccordionItemProps>`
  display: none;
  overflow: auto;
  background-color: ${color.white};
  overflow: hidden;

  strong {
    ${costaTextBold}
    font-weight: normal !important;
  }

  a {
    ${costaText}
    color: ${color.costaRed};
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    word-wrap: break-word;

    &:hover {
      color: ${color.lightRed};
      transition: 0.3s;
    }
  }

  ul {
    padding-inline-start: 40px;
    padding-left: 24px;
    &:first-child {
      margin-top: 16px;
      ${(above as any).tablet`
        margin-top: 16px;
      `}
    }
    li {
      list-style-type: inherit;
      & > p {
        margin: 0px;
      }
    }
  }

  ol {
    padding-inline-start: 40px;
    &:first-child {
      margin-top: 16px;
      ${(above as any).tablet`
        margin-top: 16px;
      `}
    }
    li {
      list-style-type: inherit;
      & > p {
        margin: 0px;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color.costaRed};
    ${(above as any).tablet`
    `}
  }

  .inline-right {
    float: right;
    max-width: 500px;
    padding: 0 0 40px 40px;
  }

  .inline-left {
    float: left;
    max-width: 500px;
    padding: 0 40px 40px 0;
  }

  &.accordionOpen {
    display: block;
    height: auto;
    padding: 0 16px;

    ${(above as any).tablet`
       padding: 0 96px 0 16px;
    `}
  }
`

const AccordionAccordionBlockContent = styled.div`
  background-color: ${color.white};
  overflow: hidden;

  strong {
    ${costaTextBold}
    font-weight: normal !important;
  }

  a {
    ${costaText}
    color: ${color.costaRed};
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    word-wrap: break-word;

    &:hover {
      color: ${color.lightRed};
      transition: 0.3s;
    }
  }

  ul {
    padding-inline-start: 40px;
    padding-left: 24px;
    &:first-child {
      margin-top: 16px;
      ${(above as any).tablet`
        margin-top: 16px;
      `}
    }
    li {
      list-style-type: inherit;
      & > p {
        margin: 0px;
      }
    }
  }

  ol {
    padding-inline-start: 40px;
    &:first-child {
      margin-top: 16px;
      ${(above as any).tablet`
        margin-top: 16px;
      `}
    }
    li {
      list-style-type: inherit;
      & > p {
        margin: 0px;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color.costaRed};
    ${(above as any).tablet`
    `}
  }

  .inline-right {
    float: right;
    max-width: 500px;
    padding: 0 0 40px 40px;
  }

  .inline-left {
    float: left;
    max-width: 500px;
    padding: 0 40px 40px 0;
  }
`

const AccordionAccordionContentWrapper = styled.div`
  display: block;
  margin: 0px auto;
  overflow: hidden;

  ${(above as any).tablet`
    padding: 40px 10%;
  `}

  ${(above as any).desktop`
    max-width: 1392px;
  `}
`

const Title = styled.h2`
  color: ${color.costaRed};
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 8px;
`

const ImageWrapper = styled.div`
  width: 100%;
  max-height: -webkit-fill-available;
  max-width: -webkit-fill-available;
  object-fit: contain;
  margin-top: 16px;

  ${(above as any).tablet`
    float: left;
    width: 45%;
    margin: 16px 24px 16px 0px
  `}

  ${(above as any).desktop`
    width: 33%;
    margin: 16px 32px 16px 0px
  `}
`

const AccordionRichTextContainer = styled.div`
  margin: 0 auto;
`

export default AccordionBlock
