/* eslint-disable react/forbid-prop-types */
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types'
import { usePageContext } from 'utils/src/pageContext'
import { getLocaleName } from './locales'

function AnalyticsDataLayer({ location, title }) {
  const { locale } = usePageContext()
  const digitalData = {
    page: {
      pageInfo: {
        pageName: '',
        pageUrl: '',
      },
      category: {
        primaryCategory: '',
        subCategory1: '',
        subCategory2: '',
        subCategory3: '',
        subCategory4: '',
        pageType: '',
        preferredLanguage: '',
        country: '',
      },
    },
    user: {
      visitorType: '',
    },
  }

  const { pathname, href } = location

  const getCategories = pagepath => {
    const categories = pagepath.split('/')
    categories.shift()
    return categories
  }

  const setPageUrl = url => {
    digitalData.page.pageInfo.pageUrl = url
  }

  const setPageName = (siteLocale, pageTitle) => {
    digitalData.page.pageInfo.pageName = `${siteLocale} | ${pageTitle}`
  }

  const setPageCategory = pagepath => {
    const [first = '', second = '', third = '', fourth = '', fifth = ''] = getCategories(pagepath)
    digitalData.page.category.primaryCategory = first
    digitalData.page.category.subCategory1 = second
    digitalData.page.category.subCategory2 = third
    digitalData.page.category.subCategory3 = fourth
    digitalData.page.category.subCategory4 = fifth
  }

  const setPageType = pageTitle => {
    const ERROR_PAGE = '404'

    if (pageTitle.includes(ERROR_PAGE)) {
      digitalData.page.category.pageType = 'errorPage'
    }

    return digitalData.page.category.pageType
  }

  const setLocale = language => {
    digitalData.page.category.preferredLanguage = language
  }

  const setCountry = language => {
    digitalData.page.category.country = getLocaleName(language)
  }

  const setVisitorType = () => {
    digitalData.user.visitorType = 'guest'
  }

  setPageUrl(href)
  setPageName(locale, title)
  setPageCategory(pathname)
  setPageType(title)
  setLocale(locale)
  setCountry(locale)
  setVisitorType()

  if (typeof window !== 'undefined') window.digitalData = digitalData

  return null
}

AnalyticsDataLayer.defaultProps = {
  location: {},
  title: '',
}

AnalyticsDataLayer.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
}

export default AnalyticsDataLayer
