import { graphql, useStaticQuery } from 'gatsby'

export const useFMCGLoserPromo = (node_locale: string) => {
  const { allContentfulBlockPromo } = useStaticQuery(graphql`
    query CoreFMCGLoserPromoQuery {
      allContentfulBlockPromo(
        filter: { cmsTitle: { eq: "FMCG Registration Form - Loser Promo" } }
      ) {
        nodes {
          ...Promo
        }
      }
    }
  `)

  return allContentfulBlockPromo.nodes.find((element: any) =>
    element.node_locale.includes(node_locale)
  )
}
