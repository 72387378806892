import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import { above } from 'ui/src/styles/index'
import useWebsiteSettings from '../../../utils/build-helpers/useWebsiteSettings'

export default function Logo({ alt }) {
  const { logo } = useWebsiteSettings()

  if (!logo) {
    return <div>logo</div>
  }

  return (
    <ImageWrapper>
      <GatsbyImage image={logo.gatsbyImageData} alt={alt || ''} />
    </ImageWrapper>
  )
}

const ImageWrapper = styled.div`
  width: 78px;
  max-height: 35px;

  img {
    object-fit: contain !important;
  }

  ${above.desktop`
    width: 205px;
    max-height: 84px;
  `};
`
