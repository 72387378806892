import React from 'react'
import BlockPromoStandard from './blockPromoStandard'
import BlockPromoTeaser from './blockPromoTeaser'
import BlockPromoCampaign from './blockPromoCampaign'
import useWebsiteSettings from '../../utils/build-helpers/useWebsiteSettings'

/**
 * @description This is a wrapper for the Promo Component. It renders
 * the different Promo variations: Standard, Campaign and Teaser.
 */
function BlockPromo({ data }) {
  const {
    style,
    waveBorder,
    standardStyleBgColour,
    campaignStyleBgColour,
    campaignStyleTextColour,
    image,
    imageAlignment = 'right',
    content,
    primaryAction,
    primaryActionLabel,
    primaryActionStyle,
    secondaryAction,
    secondaryActionLabel,
    secondaryActionStyle,
    fallbackImage,
    seasonalSVG,
    node_locale,
  } = data

  const { enableSeasonalSVGs } = useWebsiteSettings(node_locale)
  const displaySeasonalSVG = enableSeasonalSVGs && !!seasonalSVG?.file?.url
  const align = imageAlignment === 'left' ? 'row-reverse' : 'initial'

  switch (style) {
    case 'teaser':
      return (
        <BlockPromoTeaser
          image={image}
          imageAlignment={align}
          content={content}
          primaryAction={primaryAction}
          primaryActionLabel={primaryActionLabel}
          primaryActionStyle={primaryActionStyle}
          secondaryAction={secondaryAction}
          secondaryActionLabel={secondaryActionLabel}
          secondaryActionStyle={secondaryActionStyle}
          node_locale={node_locale}
        />
      )
    case 'campaign':
      return (
        <BlockPromoCampaign
          imageAlignment={align}
          campaignStyleBgColour={campaignStyleBgColour}
          content={content}
          campaignStyleTextColour={campaignStyleTextColour}
          image={image}
          primaryAction={primaryAction}
          primaryActionLabel={primaryActionLabel}
          primaryActionStyle={primaryActionStyle}
          secondaryAction={secondaryAction}
          secondaryActionLabel={secondaryActionLabel}
          secondaryActionStyle={secondaryActionStyle}
          waveBorder={waveBorder}
          fallbackImage={fallbackImage}
          seasonalSVG={seasonalSVG}
          displaySeasonalSVG={displaySeasonalSVG}
        />
      )
    case 'standard':
      return (
        <BlockPromoStandard
          standardStyleBgColour={standardStyleBgColour}
          image={image}
          imageAlignment={align}
          content={content}
          primaryAction={primaryAction}
          primaryActionLabel={primaryActionLabel}
          primaryActionStyle={primaryActionStyle}
          secondaryAction={secondaryAction}
          secondaryActionLabel={secondaryActionLabel}
          secondaryActionStyle={secondaryActionStyle}
          fallbackImage={fallbackImage}
          seasonalSVG={seasonalSVG}
          displaySeasonalSVG={displaySeasonalSVG}
        />
      )
    default:
      return null
  }
}

export default BlockPromo
