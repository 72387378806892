/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { color, above } from '../../styles'

function Accordion({ className, title, content, textColor, dataCy }) {
  const [accordionOpen, setAccordionOpen] = useState(false)
  return (
    <Wrapper className={className}>
      <TitleWrapper
        onClick={() => setAccordionOpen(!accordionOpen)}
        tabIndex={0}
        role="button"
        onKeyPress={() => setAccordionOpen(!accordionOpen)}
        aria-expanded={accordionOpen}
        data-cy={`${dataCy}__hours-accordion`}
      >
        <Title textColor={textColor}>{title}</Title>
        <Icon accordionOpen={accordionOpen} />
      </TitleWrapper>
      <Content accordionOpen={accordionOpen} data-cy={`${dataCy}__hours-accordion-content`}>
        {content.map((element, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ContentContainer key={index}>
            <p>{element.title}</p>
            <AcordionValueContent>{element.content}</AcordionValueContent>
          </ContentContainer>
        ))}
      </Content>
    </Wrapper>
  )
}

Accordion.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  content: PropTypes.array.isRequired,
  textColor: PropTypes.string,
}

Accordion.defaultProps = {
  className: '',
  textColor: color.costaRed,
}

const Wrapper = styled.div`
  cursor: pointer;
`

const Title = styled.label`
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;

  ${above.desktop`
    font-size: 18px;
    line-height: 28px;
	`};
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  line-height: 22px;
  font-size: 14px;
  cursor: default;
  p {
    margin: 0 17px 0 0;
  }
`

const AcordionValueContent = styled.p`
  && {
    margin-left: auto;
  }
`

const Icon = styled.span`
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid ${color.costaRed};
  margin-left: auto;
  transition: transform 0.3s;
  transform: rotate(180deg);
  ${({ accordionOpen }) => accordionOpen && 'transform: rotate(0)'};
`

const Content = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, overflow 0.3s ease-in-out;
  color: ${color.greyDarker};
  ${({ accordionOpen }) => accordionOpen && 'max-height: 700px;'};
`

export default Accordion
