import React from 'react'
import styled from 'styled-components'
import { FormLabel, FormValidationMessage } from '.'
import { above, color } from './styles'

interface IProps {
  id: string
  label: string
  dataCy: string
  type?: string
  placeholder?: string
  value?: string
  error?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: (value: string) => void
}

function Input({
  id,
  label,
  dataCy,
  type = 'text',
  placeholder = '',
  value = '',
  error = '',
  onBlur = () => undefined,
  onChange = () => undefined,
}: IProps): React.ReactElement {
  return (
    <>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <StyledInput
        name={id}
        data-cy={dataCy}
        type={type}
        placeholder={placeholder}
        value={value}
        error={!!error}
        onBlur={onBlur}
        onChange={e => onChange(e.target.value)}
      />
      {error && <FormValidationMessage>{error}</FormValidationMessage>}
    </>
  )
}

const StyledInput = styled.input<{ error: boolean }>`
  display: block;
  background-color: ${color.white};
  border-radius: 3px;
  padding: 0 20px;
  height: 48px;
  width: 100%;
  border: ${(props: { error: boolean }) =>
    props.error ? `1px solid ${color.lightRed}` : `1px solid ${color.greyCore}`};

  &::placeholder {
    color: ${color.greyCore} !important;
  }

  &:disabled {
    color: ${color.greyCore};
    border-color: ${color.greyCore};
    cursor: not-allowed;
  }

  ${above.tablet`
    border-width: 2px;
  `}
`

export default Input
