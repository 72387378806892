import React from 'react'
import { format, addDays, getDay, isAfter, set, subDays } from 'date-fns'
import { color } from '../styles'
import { formatDisplayTime } from '../helpers/stores'

const getNextOpenDay = (now, hours) => {
  for (let i = 1; i <= 7; i++) {
    const date = addDays(now, i)
    if (hours[`${format(date, 'EEEE').toLowerCase()}Opening`]) {
      return date
    }
  }

  return null
}

const isOpenNow = (
  now,
  todayOpeningHours,
  todayClosingHours,
  yesterdayOpeningHours,
  yesterdayClosingHours
) => {
  if (!todayOpeningHours || !todayClosingHours) {
    return false
  }

  const todayOpening = set(new Date(now), {
    hours: todayOpeningHours.split(':')[0],
    minutes: todayOpeningHours.split(':')[1],
  })

  const todayClosing = set(new Date(now), {
    hours: todayClosingHours.split(':')[0],
    minutes: todayClosingHours.split(':')[1],
  })

  const yesterdayOpening = set(new Date(now), {
    hours: yesterdayOpeningHours.split(':')[0],
    minutes: yesterdayOpeningHours.split(':')[1],
  })

  const yesterdayClosing = set(new Date(now), {
    hours: yesterdayClosingHours.split(':')[0],
    minutes: yesterdayClosingHours.split(':')[1],
  })

  const openedEarlierToday = isAfter(now, todayOpening)
  const closesLaterToday = isAfter(todayClosing, now)
  const closesAfterMidnightToday = isAfter(todayOpening, todayClosing)
  const closesAfterMidnightYesterday = isAfter(yesterdayOpening, yesterdayClosing)
  const stillOpenFromYesterday = closesAfterMidnightYesterday && isAfter(yesterdayClosing, now)
  const openToday = openedEarlierToday && (closesLaterToday || closesAfterMidnightToday)

  return openToday || stillOpenFromYesterday
}

const isOpenLaterToday = (now, todayOpeningHours) => {
  const todayOpening = set(new Date(now), {
    hours: todayOpeningHours.split(':')[0],
    minutes: todayOpeningHours.split(':')[1],
  })
  return isAfter(todayOpening, now)
}

function StoreOpenStatus({
  now = new Date(),
  hours,
  timeFormat = '24',
  storeOpenText,
  storeClosedText,
  opensText,
  openTomorrowText,
  storeOpen24HoursText,
  store24HoursText,
  closesText,
  mondayText,
  tuesdayText,
  wednesdayText,
  thursdayText,
  fridayText,
  saturdayText,
  sundayText,
}) {
  if (!hours) {
    return null
  }

  const todayDay = format(now, 'EEEE')
  const yesterdayDay = format(subDays(now, 1), 'EEEE')
  const tomorrowDay = format(addDays(now, 1), 'EEEE')

  const yesterdayOpeningHours = hours[`${yesterdayDay.toLowerCase()}Opening`]
  const yesterdayClosingHours = hours[`${yesterdayDay.toLowerCase()}Closing`]
  const todayOpeningHours = hours[`${todayDay.toLowerCase()}Opening`]
  const todayClosingHours = hours[`${todayDay.toLowerCase()}Closing`]
  const tomorrowOpeningHours = hours[`${tomorrowDay.toLowerCase()}Opening`]

  const open24HoursKey = 'Open 24 hours'
  const open24HourText = `(${store24HoursText})`

  // Open now for 24 hours
  if (todayOpeningHours === open24HoursKey) {
    return <span style={{ color: `${color.successGreen}` }}>{storeOpen24HoursText}</span>
  }

  // Open now and closes later
  if (
    isOpenNow(
      now,
      todayOpeningHours,
      todayClosingHours,
      yesterdayOpeningHours,
      yesterdayClosingHours
    )
  ) {
    return (
      <>
        <span style={{ color: `${color.successGreen}` }}>{storeOpenText}</span>
        <span>{` ${closesText} ${formatDisplayTime(todayClosingHours, timeFormat)}`}</span>
      </>
    )
  }

  // Closed now and opens later today
  if (isOpenLaterToday(now, todayOpeningHours)) {
    return (
      <>
        <span style={{ color: `${color.alertError}` }}>{storeClosedText}</span>
        <span>{` ${opensText} ${formatDisplayTime(todayOpeningHours, timeFormat)}`}</span>
      </>
    )
  }

  // Closed now and opens tomorrow
  if (tomorrowOpeningHours) {
    const openTomorrowTime =
      tomorrowOpeningHours === open24HoursKey
        ? open24HourText
        : formatDisplayTime(tomorrowOpeningHours, timeFormat)
    return (
      <>
        <span style={{ color: `${color.alertError}` }}>{storeClosedText}</span>
        <span>{` ${openTomorrowText} ${openTomorrowTime}`}</span>
      </>
    )
  }

  // Closed now and opens within the next 7 days
  const nextOpenDay = getNextOpenDay(now, hours)
  if (nextOpenDay) {
    const nextOpeningDay = [
      sundayText,
      mondayText,
      tuesdayText,
      wednesdayText,
      thursdayText,
      fridayText,
      saturdayText,
    ][getDay(nextOpenDay)]
    const nextOpeningTime = hours[`${format(nextOpenDay, 'EEEE').toLowerCase()}Opening`]
    const time =
      nextOpeningTime === open24HoursKey
        ? open24HourText
        : formatDisplayTime(nextOpeningTime, timeFormat)
    return (
      <>
        <span style={{ color: `${color.alertError}` }}>{storeClosedText}</span>
        <span>{` ${opensText} ${nextOpeningDay} ${time}`}</span>
      </>
    )
  }

  // Store is closed
  return null
}

export default StoreOpenStatus
