import React from 'react'
import styled from 'styled-components'

import { costaTextBold } from '../../styles'

function LabelCTA({
  className,
  arrowDirection,
  children,
  labelLink,
  color,
  onClick,
  target,
  dataCy,
}) {
  return (
    <Link
      className={className}
      arrowDirection={arrowDirection}
      onClick={onClick}
      href={labelLink}
      color={color}
      target={target}
      data-cy={dataCy}
    >
      {children}
    </Link>
  )
}

LabelCTA.defaultProps = {
  color: '#6d1f37',
  arrowDirection: 'right',
}

const Link = styled.a`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  height: 24px;
  ${costaTextBold};
  ${({ arrowDirection, color }) =>
    arrowDirection === 'right'
      ? `
  :after {
    border-left: 8px solid ${color};
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    margin-left: 16px;
  }`
      : `:before {
    border-right: 8px solid ${color};
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    margin-right: 16px;
  }`}
`

export default LabelCTA
