import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

function Toggle({ toggleWidget, className, toggleAlt, toggled }) {
  return (
    <StyledToggle toggled={toggled}>
      <button
        alt={toggleAlt}
        onClick={toggleWidget}
        onKeyDown={e => {
          if (!(e.keyCode === 9) || e.keyCode === 16) {
            toggleWidget()
          }
          if (e.keyCode === 13) {
            toggleWidget()
          }
        }}
        className={className}
        type="button"
        data-cy="sidebar__toggle"
        data-testid="sidebar__toggle"
      >
        <StyledChevron toggled={toggled} />
      </button>
    </StyledToggle>
  )
}

const StyledToggle = styled.div`
  width: 38px;
  height: 40px;
  position: absolute;

  button {
    background: #6d1f37;
    padding: 0.625em 0.438em;
    border: none;
    position: absolute;
    z-index: 1;
    border-radius: 0 20px 20px 0;
    display: block;
    cursor: pointer;
    margin: 0;

    ${({ toggled }) => toggled && 'left: 0;'}
  }
`

const StyledChevron = styled.span`
  border-color: #ffffff;
  border-width: 0px 0px 2.5px 2.5px;
  border-style: solid;
  width: 16px;
  height: 16px;
  display: block;

  transform: rotate(45deg);
  margin: 0 0 0 8px;

  ${({ toggled }) =>
    toggled &&
    `   transform: rotate(225deg);
        margin: 0 0.438em 0 0;`}
`

export default Toggle

Toggle.defaultProps = {
  toggled: false,
}

Toggle.propTypes = {
  toggleWidget: PropTypes.func.isRequired,
  toggled: PropTypes.bool,
  toggleAlt: PropTypes.string.isRequired,
}
