/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'

import StoreMarker from '../StoreMarker/storeMarker'
import { storeMarker } from '../helpers/stores'
import { StoreLocatorContext } from '../reducers/combineReducers'
import { above } from '../styles'
import { updateQueryString } from '../helpers/requestLocationHelpers'

function StoreLocatorMap({
  mapCenter,
  zoom,
  apiKey,
  stores,
  mobileMap,
  dataCyScreenSize,
  onDrag = () => {},
}) {
  const [state, dispatch] = useContext(StoreLocatorContext)

  const updateSelectedStore = (event, store) => {
    event.preventDefault()

    dispatch({
      type: 'setSelectedLocation',
      payload: { lat: Number.parseFloat(store.latitude), lng: Number.parseFloat(store.longitude) },
    })
    dispatch({ type: 'setSelectedStore', payload: store })
  }

  return (
    <StyledStoreLocatorMap
      data-cy={`map-${dataCyScreenSize}`}
      data-testid={`map-${dataCyScreenSize}`}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: apiKey,
          libraries: ['places'],
        }}
        defaultCenter={{ lat: 51.504831314, lng: -0.123499506 }}
        center={mapCenter}
        onDrag={onDrag}
        onDragEnd={() => dispatch({ type: 'showChangeLocationButton', payload: true })}
        onChange={({ center }) => {
          updateQueryString(center.lat, center.lng)
          dispatch({ type: 'setMovedMapLocation', payload: center })
        }}
        defaultZoom={zoom}
        selectedLocation={state.location.selectedLocation}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          gestureHandling: 'greedy',
          zoomControl: false,
        }}
      >
        {stores.map(store => (
          <StoreMarker
            key={store.storeNo8Digit}
            lat={Number.parseFloat(store.latitude)}
            lng={Number.parseFloat(store.longitude)}
            alt={store.storeNameInternal}
            storeType={
              process.env.GATSBY_BUDDY_PIPELINE === 'uk'
                ? storeMarker(store.storeType)
                : store.storeTypeIcon
            }
            onClick={event => {
              dispatch({ type: 'showFilters', payload: false })
              !mobileMap && dispatch({ type: 'showStoreCard', payload: true })
              dispatch({ type: 'showSideBar', payload: true })
              updateSelectedStore(event, store)
            }}
            active={store.storeNo8Digit === state.stores.selectedStore.storeNo8Digit}
            onKeyDown={event => updateSelectedStore(event, store)}
            type={store.storeType}
          />
        ))}
      </GoogleMapReact>
    </StyledStoreLocatorMap>
  )
}

const StyledStoreLocatorMap = styled.div`
  height: calc(100vh - 80px);
  width: '100%';

  ${above.desktop`
    height: calc(100vh - 148px);
  `};
`

StoreLocatorMap.defaultProps = {
  zoom: 12,
  mapCenter: {
    lat: 51.504831314,
    lng: -0.123499506,
  },
}

StoreLocatorMap.propTypes = {
  apiKey: PropTypes.string.isRequired,
  mapCenter: PropTypes.object,
  zoom: PropTypes.number,
}

export default StoreLocatorMap
