import React from 'react'
import styled from 'styled-components'
import { color, costaTextBold } from './styles'

// TODO allow changing the tag - https://styled-components.com/docs/api#as-polymorphic-prop

interface IProps {
  dataCy: string
  children: React.ReactNode
}

function Heading({ dataCy, children }: IProps): React.ReactElement {
  return <H1 data-cy={dataCy}>{children}</H1>
}

const H1 = styled.h1`
  color: ${color.costaRed};
  ${costaTextBold};
  text-align: center;
  margin: 0;
  padding: 0 0 32px 0;
`

export default Heading
