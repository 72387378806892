/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { color } from '../../styles'

function StoreCardService({ serviceName, icon, className, dataCy }) {
  return (
    <Wrapper
      className={className}
      data-cy={`${dataCy}__services__${serviceName?.replace(/\s/g, '-').toLowerCase()}`}
    >
      <ServiceIcon src={icon?.src} alt={icon?.description} color={color.burntOrange} />
      <p>{serviceName}</p>
    </Wrapper>
  )
}

StoreCardService.propTypes = {
  serviceName: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  className: PropTypes.string,
}

StoreCardService.defaultProps = {
  className: '',
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ServiceIcon = styled.img`
  margin-right: 8px;
`

export default StoreCardService
