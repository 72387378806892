import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { GatsbyImage } from 'gatsby-plugin-image'

import styled from 'styled-components'
import isRtl from 'utils/src/rtl'
import { above, color } from 'ui/src/styles/index'
import RichText from '../richText'
import LinkCta from '../linkCta'

function BlockPromoTeaser({
  imageAlignment,
  image,
  content,
  primaryAction,
  primaryActionLabel,
  primaryActionStyle,
  secondaryAction,
  secondaryActionLabel,
  secondaryActionStyle,
  node_locale,
}) {
  return (
    <Wrapper imageAlignment={imageAlignment}>
      <ImageWrapper imageAlignment={imageAlignment}>
        <GatsbyImage
          style={{ height: '100%', width: '100%' }}
          image={image.gatsbyImageData}
          alt={image.description}
        />
      </ImageWrapper>
      <Content locale={node_locale}>
        <RichText data={content} />
        {primaryAction && (
          // Custom colour prop to be changed if we make the CTA colour authorable for the block promo.
          <StyledLinkCta
            link={primaryAction}
            ctaStyle={primaryActionStyle || 'button'}
            alignment="left"
            customColor={color.costaRed}
          >
            {primaryActionLabel || primaryAction.title}
          </StyledLinkCta>
        )}
        {secondaryAction && (
          <StyledLinkCta
            link={secondaryAction}
            ctaStyle={secondaryActionStyle || 'link'}
            alignment="left"
          >
            {secondaryActionLabel || secondaryAction.title}
          </StyledLinkCta>
        )}
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.article`
  background-color: white;
  padding-top: 40px;
  padding-left: 5%;
  padding-right: 5%;
  margin: 0 2.5%;
  align-items: flex-start;

  ${above.tablet`
    margin: 0 6%;
  `}

  ${above.tabletLarge`
    display: flex;
    flex-direction: ${p => (p.imageAlignment === 'row-reverse' ? 'row' : 'row-reverse')};
    margin: 0 9%;
    max-width: 1274px;
  `}

  ${above.desktopLarge`
    padding-left: 118px;
    padding-right: 118px;
    margin: 0 4%;
  `}
`

const ImageWrapper = styled.div`
  margin: 0 0 21px 0;

  ${above.tablet`
    width: 100%;
    min-height: 200px;
  `}

  ${above.tabletLarge`
    width: 70%;
    margin: 0 2rem 1rem 56px;
    margin-left: ${p => (p.imageAlignment === 'row-reverse' ? '0' : '2rem')};
    margin-right: ${p => (p.imageAlignment !== 'row-reverse' ? '0' : '56px')};
  `}

  ${above.desktopLarge`
    width: 50%;
    max-height: 220px;
  `}
`

const Content = styled.div`
  width: 100%;
  color: ${color.greyDarker};
  padding-bottom: 16px;

  ${above.desktopLarge`
    padding-bottom: 0;
  `}

  h1, h2, h3, h4, h5, h6 {
    color: ${color.costaRed};
    margin: 0 0 8px 0;
    width: 100%;
    text-align: ${p => (isRtl(p.locale) ? 'right' : 'left')};
  }

  p {
    margin: 0;
    color: ${color.greyDarker};
    width: 100%;

    ${above.desktop`
      padding: 0;
    `};
  }
`

const StyledLinkCta = styled(LinkCta)`
  margin-top: 16px;
`

export default BlockPromoTeaser
