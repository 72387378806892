/* eslint-disable no-nested-ternary */
import React from 'react'
import ReactPlayer from 'react-player'
// eslint-disable-next-line import/no-extraneous-dependencies
import { GatsbyImage } from 'gatsby-plugin-image'

import styled from 'styled-components'
import { above, color } from 'ui/src/styles/index'
import RichText from '../richText'
import LinkCta from '../linkCta'

function BlockPromoStandard({
  standardStyleBgColour,
  imageAlignment,
  image,
  content,
  primaryAction,
  primaryActionLabel,
  primaryActionStyle,
  secondaryAction,
  secondaryActionLabel,
  secondaryActionStyle,
  fallbackImage,
  seasonalSVG,
  displaySeasonalSVG,
}) {
  const mediaType = image.file.contentType
  const bgColour =
    standardStyleBgColour && standardStyleBgColour === 'Cloudless Blue'
      ? color.rebrandBlue
      : standardStyleBgColour === 'Nightfall Lilac'
      ? color.secondaryCore
      : standardStyleBgColour === 'Sunset Coral'
      ? color.tertiaryCore
      : standardStyleBgColour === 'Grey'
      ? color.offWhite
      : color.white

  const backUpImage = fallbackImage?.file?.url
  return (
    <Wrapper>
      {displaySeasonalSVG && (
        <SeasonalSVG src={seasonalSVG.file.url} imageAlignment={imageAlignment} alt="" />
      )}
      <BlockWrapper bgColour={bgColour} imageAlignment={imageAlignment}>
        <Content bgColour={bgColour}>
          <RichText data={content} />
          {primaryAction && (
            <PrimaryActionLinkCta
              link={primaryAction}
              ctaStyle={primaryActionStyle || 'button'}
              alignment="center"
              bgColour={bgColour}
            >
              {primaryActionLabel || primaryAction.title}
            </PrimaryActionLinkCta>
          )}
          {secondaryAction && (
            <StyledLinkCta
              link={secondaryAction}
              ctaStyle={secondaryActionStyle || 'button'}
              alignment="center"
            >
              {secondaryActionLabel || secondaryAction.title}
            </StyledLinkCta>
          )}
        </Content>
        {mediaType.includes('image') && (
          <ImageWrapper>
            <GatsbyImage
              style={{ height: '100%', minHeight: '300px', width: '100%' }}
              image={image.gatsbyImageData}
              alt={image.description}
            />
          </ImageWrapper>
        )}
        {mediaType.includes('video') && (
          <VideoWrapper>
            <ReactPlayer
              url={image.file.url}
              config={{
                file: {
                  attributes: {
                    poster: `${backUpImage}`,
                  },
                },
              }}
              playsinline
              playing
              loop
              muted
              wrapper={ReactPlayerWrapper}
              height="100%"
              width="100%"
            />
          </VideoWrapper>
        )}
      </BlockWrapper>
    </Wrapper>
  )
}

export default BlockPromoStandard

const Wrapper = styled.article`
  position: relative;
`

const ReactPlayerWrapper = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const BlockWrapper = styled.div`
  background-color: ${p => p.bgColour};
  margin: 80px auto;

  ${above.tablet`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: ${p => p.imageAlignment};
  `}

  ${above.desktopLarge`
    max-width: 1392px;
  `};
`

const Content = styled.div`
  width: 100%;
  background-color: ${p => p.bgColour || 'white'};
  color: ${color.greyDarker};
  padding: 48px 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 300px;

  ${above.mobile`
    padding: 96px 10%;
  `}

  ${above.tablet`
    width: 50%;
    padding: 80px 5%;
    min-height: 512px;
  `}

  ${above.desktop`
    padding: 80px 6.5%;
  `}

  h1,h2,h3,h4,h5,h6 {
    color: ${color.costaRed};
    margin: 0 0 24px 0;
    width: 100%;

    ${above.tablet`
      margin-top: 0;
    `}
  }

  p {
    margin-top: 0;
    color: ${color.greyDarker};
    width: 100%;

    ${above.desktop`
      padding: 0px 32px;
    `};
  }

  a {
    margin-bottom: 0;
  }
`

const StyledLinkCta = styled(LinkCta)`
  margin-top: 16px;

  a {
    margin-bottom: 0 !important;
  }
`

const PrimaryActionLinkCta = styled(StyledLinkCta)`
  a {
    background: ${p => (p.ctaStyle === 'link' ? p.bgColour : color.costaRed)};
  }
`

const ImageWrapper = styled.div`
  width: auto;
  margin: 0;
  min-height: 300px;

  img {
    margin-bottom: 0;
  }

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `}
`

const VideoWrapper = styled.div`
  display: flex;
  min-height: 73px;
  position: relative;

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `};

  video {
    display: block;
    object-fit: cover;
  }
`

const SeasonalSVG = styled.img`
  left: ${p => (p.imageAlignment === 'initial' ? '10%' : 'auto')};
  right: ${p => (p.imageAlignment === 'row-reverse' ? '10%' : 'auto')};
  z-index: -1;
  position: absolute;
  top: -64px;
  max-width: 100px;
  max-height: 100px;
`
