// extracted by mini-css-extract-plugin
export var charactersLabel = "textArea-module--charactersLabel--8c13d";
export var container = "textArea-module--container--3c107";
export var description = "textArea-module--description--6d11d";
export var error = "textArea-module--error--76425";
export var errorMessage = "textArea-module--errorMessage--18ad7";
export var heading = "textArea-module--heading--ea6dc";
export var narrow = "textArea-module--narrow--ef21e";
export var stylesWithError = "textArea-module--stylesWithError--b56e5";
export var textArea = "textArea-module--textArea--658ca";
export var textError = "textArea-module--textError--c0664";