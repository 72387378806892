import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { above, baseBodyText, color } from '../../styles'

import searchIcon from '../../assets/icons/search-icon.svg'

function SearchBar({
  className,
  name,
  placeholder,
  dataCy,
  onClick,
  inputAriaLabel,
  onKeyUp,
  btnAriaLabel,
  btnTxt,
  onBlur,
  onFocus,
}) {
  return (
    <SearchBarWrapper className={className}>
      <Input
        className={className}
        data-cy={`${dataCy}__search-bar__input`}
        data-testid={`${dataCy}__search-bar__input`}
        type="text"
        id={name}
        name={name}
        placeholder={placeholder}
        aria-label={inputAriaLabel}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <Button
        data-cy={`${dataCy}__search-bar__btn`}
        aria-label={btnAriaLabel}
        role="button"
        onClick={onClick}
      >
        {btnTxt}
      </Button>
    </SearchBarWrapper>
  )
}

const SearchBarWrapper = styled.div`
  display: flex;
  height: 48px;
  width: 100%;

  ${above.tablet`
    height: 56px;
  `}
`

const Input = styled.input`
  ${baseBodyText};
  width: 100%;
  padding-left: 24px;

  border-style: solid;
  border-color: ${color.greyLight};
  border-width: 2px 0 2px 2px;
  border-radius: 25px 0 0 25px;
  color: ${color.greyDarker};

  ${above.tablet`
    border-radius: 30px 0 0 30px;
  `}

  &:focus {
    box-shadow: ${color.secondaryCore} 0px 0px 8px 2px;
    outline: none;
  }
`

const Button = styled.button`
  border-width: 0;
  background-color: ${color.costaRed};
  border-radius: 0 25px 25px 0;
  padding: 12px 20px 12px 18px;
  width: 60px;
  cursor: pointer;

  &:hover {
    background-color: #bf0341;
  }

  ${above.tablet`
    padding: 12px 24px 12px 20px;
    border-radius: 0 30px 30px 0;
    width: 72px;
  `};

  &:focus {
    box-shadow: ${color.secondaryCore} 0px 0px 8px 2px;
    outline: none;
  }

  &::before {
    display: inline-block;
    background-size: cover;
    content: '';
    background-image: url(${searchIcon});
    width: 24px;
    height: 24px;

    ${above.tablet`
      width: 28px;
      height: 28px;
    `}
  }
`

SearchBar.propTypes = {
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputAriaLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dataCy: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  btnAriaLabel: PropTypes.string.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
}

export default SearchBar
