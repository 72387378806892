import React from 'react'
import styled from 'styled-components'
import { color, costaTextBold } from './styles'

interface IProps {
  htmlFor: string
  children: React.ReactNode
}

function FormLabel({ htmlFor, children }: IProps): React.ReactElement {
  return <StyledLabel htmlFor={htmlFor}>{children}</StyledLabel>
}

const StyledLabel = styled.label`
  ${costaTextBold};
  color: ${color.costaRed};
  margin-bottom: 16px;
  display: inline-block;
`

export default FormLabel
