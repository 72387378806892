import React from 'react'
import styled from 'styled-components'
import { color, costaTextBold, above } from 'ui/src/styles'

function Heading({
  text,
  dataCy,
  size = 1,
  align = 'center',
  subtitle = '',
  anchor = '',
  className = '',
}) {
  const HeaderTag = `h${size}`
  return (
    <StyledHeading id={anchor} className={`container ${[align]} ${className}` || ''}>
      <HeaderTag data-cy={`${dataCy}__heading`} className="header headerText">
        {text}
      </HeaderTag>
      <p data-cy={`${dataCy}__subtitle`} className={`subtitle ${!subtitle && 'hidden'} `}>
        {subtitle}
      </p>
    </StyledHeading>
  )
}

export default Heading

const StyledHeading = styled.div`
  &.container {
    overflow: hidden;
  }

  &.center {
    .header,
    .subtitle {
      text-align: center;
    }
  }

  &.left {
    .header,
    .subtitle {
      text-align: left;
    }
  }

  &.right {
    .header,
    .subtitle {
      text-align: right;
    }
  }

  .hidden {
    display: none;
  }

  .header {
    color: ${color.costaRed};
    ${costaTextBold};
    text-align: center;
  }

  h1.header,
  h3.header {
    padding: 0px 32px;

    ${above.tablet`
      margin: 60px 0 20px;
      padding: 0px;
    `}
  }

  .subtitle {
    text-align: center;
    font-size: 16px;

    ${above.tablet`
      font-size: 18px;
    `}
  }
`
