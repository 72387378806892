/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components'

import { above, color } from 'ui/src/styles/index'

import PromoGridPrimaryButton from './promoGridPrimaryButton'

function PromoGridCollection({ data: { heading, promos } }) {
  return (
    <GridWrapper data-cy="grid-promo__wrapper">
      {heading && <Header>{heading}</Header>}
      <PromoWrappers count={promos.length}>
        {promos.map(
          ({
            image,
            content,
            backgroundColour,
            brandStyleBgColour,
            headingTextColour,
            ctaColour,
            textColour,
            id,
            mobileImage,
            linkCTA,
            linkTextColor,
            ctaStyle,
          }) => {
            return (
              <PromoGridPrimaryButton
                key={id}
                image={image}
                content={content}
                backgroundColour={backgroundColour}
                brandStyleBgColour={brandStyleBgColour}
                headingTextColour={headingTextColour}
                textColour={textColour}
                ctaColour={ctaColour}
                mobileImage={mobileImage}
                linkCTA={linkCTA?.[0]}
                linkTextColor={linkTextColor}
                ctaStyle={ctaStyle}
              />
            )
          }
        )}
      </PromoWrappers>
    </GridWrapper>
  )
}

const Header = styled.h3`
  width: 252px;
  margin-left: auto;
  margin-right: auto;
  color: ${color.costaRed};
  text-align: center;
  margin-bottom: 56px;

  ${above.tablet`
     width: 550px;
	`}
  ${above.tablet`
     width: 656px;
	`}

  ${above.desktop`
     width: 100%;
	`}
`

const PromoWrappers = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  ${above.tablet`
  ${({ count }) =>
    count === 4
      ? 'grid-template-columns: 1fr 1fr'
      : `grid-template-columns: repeat(${count}, 1fr)`};
`}
  ${above.desktop`
  ${({ count }) => `grid-template-columns: repeat(${count}, minmax(0,1fr))`};
  
`}
`

const GridWrapper = styled.article`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 80px 0;

  ${above.mobile`
    flex-wrap: no-wrap;
    margin: 0 auto;
	`}

  ${above.tablet`
    flex-wrap: no-wrap;
    flex-direction: row;
	`}

  ${above.desktop`
    flex-direction: row;
	`}
`

export default PromoGridCollection
