import React from 'react'
import styled from 'styled-components'
import FormValidationMessage from '../FormValidationMessage'
import { above, color, costaText } from '../styles'
import tick from './tick.png'

interface IProps {
  id: string
  dataCy: string
  children: React.ReactNode
  value: boolean
  error?: string
  onChange?: (checked: boolean) => void
}

function Checkbox({
  id,
  dataCy,
  children,
  value,
  error = '',
  onChange = () => undefined,
}: IProps): React.ReactElement {
  return (
    <>
      <LabelWrapper htmlFor={id}>
        <HiddenCheckbox
          id={id}
          name={id}
          data-cy={dataCy}
          type="checkbox"
          checked={value}
          onChange={e => onChange(e.target.checked)}
        />
        <CustomCheckbox />
        <StyledText>{children}</StyledText>
      </LabelWrapper>
      {error && <FormValidationMessage>{error}</FormValidationMessage>}
    </>
  )
}

const LabelWrapper = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  vertical-align: top;
`

const StyledText = styled.span`
  ${costaText};
  color: ${color.greyDark};

  & a {
    color: ${color.lightRed};
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }

  & p {
    margin: 0;
  }
`

const CustomCheckbox = styled.span`
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  border: 1px solid ${color.greyCore};
  border-radius: 3px;
  background-color: ${color.white};

  ${above.tablet`
    border-width: 2px;
  `}
`

const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked + ${CustomCheckbox} {
    background: url(${tick}) no-repeat center center;
    background-size: 60%;
    background-color: ${color.costaRed};
  }

  &:is(:focus, :active) + ${CustomCheckbox} {
    box-shadow: 0px 0px 8px 2px ${color.secondaryCore};
  }
`

export default Checkbox
