/* eslint-disable import/no-extraneous-dependencies */

import React from 'react'
import styled from 'styled-components'
import BusinessContactUsFormNorway from 'cw-gatsby/src/components/BusinessContactUsFormNorway/businessContactUsFormNorway'
import { above } from 'ui/src/styles'

import OneTrustCookieList from './OneTrustCookieList/oneTrustCookieList'
import FMCGRegistrationForm from './FMCGRegistrationForm/FMCGRegistrationForm'

const StaticComponents = require('./components')

export default function StaticComponent({ component, node_locale }) {
  switch (component) {
    case StaticComponents.BUSINESS_CONTACT_US_FORM_NORWAY:
      return (
        <Container>
          <BusinessContactUsFormNorway />
        </Container>
      )
    case StaticComponents.ONE_TRUST_COOKIE_LIST:
      return (
        <Container>
          <OneTrustCookieList />
        </Container>
      )
    case StaticComponents.FMCG_REGISTRATION_FORM:
      return <FMCGRegistrationForm node_locale={node_locale} />

    default:
      return null
  }
}

const Container = styled.div`
  margin: 0px 2.5%;
  ${above.tablet`
    margin: 0px 6%;
  `}
  ${above.tabletLarge`
    margin: 0px 9%;
  `}
  
  ${above.desktopLarge`
    margin: 0px 4%;
    max-width: 1274px;
  `}
`
