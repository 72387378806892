import { useStaticQuery, graphql } from 'gatsby'

const localeFormatter = locale => {
  return locale.substring(0, 2)
}

const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE

const usePageAssemblyPath = (slug, locale) => {
  const formattedLocale = localeFormatter(locale)
  const { allSitePage } = useStaticQuery(graphql`
    query AllSitePageQuery {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)

  const { path } = allSitePage.nodes.find(
    webPage => webPage.path.endsWith(`/${slug}`) || webPage.path.endsWith(`/${slug}/`)
  )

  if (defaultLocale === locale) {
    return path
  }

  return `/${formattedLocale}${path}`
}

export default usePageAssemblyPath
