import React from 'react'
import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { color, above, costaTextBold } from 'ui/src/styles/index'
import { ContentfulLink } from '../../utils/link'

function LinkCta({ alignment, ctaStyle, link, customColor, customLinkColor, className, children }) {
  return (
    <StyledBlockLink
      className={className}
      modifiers={[ctaStyle || 'button', alignment || 'left']}
      customColor={customColor}
      customLinkColor={customLinkColor}
    >
      <ContentfulLink linkData={link}>{children}</ContentfulLink>
    </StyledBlockLink>
  )
}

const MODIFIER_CONFIG = {
  center: () => 'text-align: center',
  left: () => 'text-align: left',
  right: () => 'text-align: right',
  button: ({ customColor }) => `
    a {
      pointer-events: auto;
      background: ${customColor};
      color: ${customColor === color.white ? color.costaRed : color.white};
      padding: 15px 20px;
      border-radius: 32px;

      ${above.tablet`
        padding: 18px 27px;
      `}

      &:hover {
        background: ${customColor === color.white ? color.offWhite : color.lightRed};
      }

      &:after {
        border-left: 6px solid ${customColor === color.white ? color.costaRed : color.white};
      }
    }
  `,

  link: ({ customLinkColor }) => `
    a {
      color: ${customLinkColor === color.white ? color.white : color.costaRed};
      padding: 15px 0;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;

      ${above.tablet`
        padding: 18px 0;
      `}

      &:after {
        border-left: 6px solid ${customLinkColor === color.white ? color.white : color.costaRed};
      }

      &:hover {
        color: ${color.lightRed};
        &:after {
        border-left: 6px solid ${color.lightRed};
      }
      }
    }
  `,
}

const StyledBlockLink = styled.div`
  a {
    ${costaTextBold}
    display: inline-block;
    letter-spacing: 1px;
    cursor: pointer;
    margin-bottom: 16px;

    ${above.tablet`
      margin-bottom: 18px;
    `}

    &:after {
      content: '';
      display: inline-block;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      margin-left: 16px;

      ${above.tablet`
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      `}
    }
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`

export default LinkCta
