/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
import React, { useContext, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import TextButton from '../elements/Button/TextButton/textButton'
import { StoreLocatorContext } from '../reducers/combineReducers'
import IconButton from '../elements/Button/IconButton/iconButton'
import PrimaryButton from '../elements/Button/PrimaryButton/primaryButton'
import Checkbox from '../elements/Checkbox/CheckboxWithLabel/checkboxWithLabel'
import closeIcon from '../assets/icons/cross.svg'
import { above, color, costaText } from '../styles'

import filters from '../helpers/getFilterList'

function StoreFilters({
  className,
  clearBtnLabel,
  closeBtnLabel,
  storeFiltersLegend,
  storeFiltersHeading,
  applyBtnLabel,
  handleClose,
  handleChecked,
  invalid,
  noResults,
  country,
  storeTypes,
  dataCyScreenSize,
}) {
  const urlFilter = typeof window !== 'undefined' ? new URL(window.location.href) : null
  const [state, dispatch] = useContext(StoreLocatorContext)
  const filtersCopy = country === 'uk' ? filters[country] : { storeTypes }
  const { stores, app } = state

  // clear on open each time if no filter param
  if (!urlFilter.searchParams.has('filter')) {
    filtersCopy.storeTypes.map(storeType => {
      storeType.active = false
    })
  }

  const getFilteredStores = (filter, parent) => {
    const checkedFilters = new Set([])
    // eslint-disable-next-line consistent-return
    const filteredStores = stores.allStores.filter(store => {
      let result = true
      filtersCopy.storeTypes.forEach(({ facilities = [], active, name }) => {
        if (active) {
          checkedFilters.add(name)
        }
        facilities.forEach(facility => {
          if (facility.active) {
            checkedFilters.add(facility.name)
          }
        })
      })
      const checkedFiltersArray = Array.from(checkedFilters)

      dispatch({ type: 'setActiveFilterCount', payload: checkedFiltersArray.length })

      checkedFiltersArray.forEach(checkedFilter => {
        const facilityArray = Object.keys(store.storeFacilities)
        const filterName = parent ? parent.name : filter.name
        if (filterName === checkedFilter && store.storeType !== checkedFilter) {
          result = false
        }
        facilityArray.forEach(indexedFacility => {
          const facilityActive = store.storeFacilities[indexedFacility].active
          const facilityName = store.storeFacilities[indexedFacility].name

          if (facilityName === checkedFilter && !facilityActive) {
            result = false
          }
        })
      })

      if (filter && store.storeType === filter.name) {
        result = true
        return store
      }
      return result && store
    })
    let activeFilters
    filtersCopy.storeTypes.some(type => {
      const activeFacilities = type.facilities.find(facility => facility.active === true) || []
      if (activeFacilities.length !== 0 || type.active === true) {
        activeFilters = true
        return true
      }
      activeFilters = false
      return false
    })
    return activeFilters === false ? stores.allStores : filteredStores
  }

  const clearFilters = () => {
    filtersCopy.storeTypes.map(storeType => {
      storeType.facilities.map(facility => {
        facility.active = false
      })
      storeType.active = false
    })
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete('filter')
    window.history.replaceState(null, '', `${window.location.pathname}?${searchParams.toString()}`)
  }

  const handleClear = () => {
    clearFilters()
    dispatch({ type: 'clear' })
    dispatch({ type: 'setStores', payload: getFilteredStores() })
  }

  const handleOnClick = (filter, parent) => {
    urlFilter.searchParams.delete('filter')
    window.history.replaceState(null, '', urlFilter.href)
    filter.active = !filter.active

    if (parent === undefined) {
      if (filter.active) {
        urlFilter.searchParams.set('filter', filter.name)
      } else {
        urlFilter.searchParams.delete('filter')
      }
      window.history.replaceState(null, '', urlFilter.href)
    }
    // Keep store type filters independent
    if (
      parent === undefined &&
      filter.active === true &&
      filter.facilities.every(facility => facility.active === false)
    ) {
      handleClear()
      filter.active = true
      urlFilter.searchParams.set('filter', filter.name)
      window.history.replaceState(null, '', urlFilter.href)
    }

    // Enable store type when facility is checked
    if (parent && filter.active) {
      parent.active = true
    }

    // Turn off the other store types when facility is checked
    if (parent) {
      filtersCopy.storeTypes.map(storeType => {
        if (storeType.name !== parent.name) {
          urlFilter.searchParams.delete('filter')
          storeType.active = false
        }
      })
    }

    // Clear facilities when store type is unchecked
    if (parent === undefined && filter.active === false) {
      handleClear()
    }

    dispatch({ type: 'setStores', payload: getFilteredStores(filter, parent) })
    dispatch({ type: 'resetFilters' })
    handleChecked()
  }

  const dataCyPrefix = `filters-${dataCyScreenSize}`

  urlFilter.searchParams.has('filter') &&
    filtersCopy.storeTypes.map(storeType => {
      if (storeType.name === urlFilter.searchParams.get('filter')) {
        storeType.active = true
      }
    })

  // reset filters when cleared
  if (app.clear && !urlFilter.searchParams.has('filter')) {
    filtersCopy.storeTypes.map(storeType => {
      storeType.facilities.map(facility => {
        facility.active = false
      })
      storeType.active = false
    })
  }

  return (
    <StoreFiltersWrapper data-cy={dataCyPrefix}>
      <StoreFiltersHeader>
        <TextButton
          className={className}
          dataCy={`${dataCyPrefix}__clear`}
          label={clearBtnLabel}
          contentColor={color.costaRed}
          onClick={() => handleClear()}
        />
        <StoreFiltersHeading className={className} data-cy={`${dataCyPrefix}__heading`}>
          {storeFiltersHeading}
        </StoreFiltersHeading>
        <CloseButton
          className={className}
          dataCy={`${dataCyPrefix}__close`}
          ariaLabel={closeBtnLabel}
          icon={closeIcon}
          iconAlt={closeBtnLabel}
          onClick={() => {
            handleClear()
            handleClose()
          }}
        />
      </StoreFiltersHeader>
      <StoreFiltersCheckboxes>
        <legend>{storeFiltersLegend}</legend>
        {filtersCopy.storeTypes.map((store, storeTypeIndex) => {
          const facilitiesCheckbox = store.facilities?.map((facility, facilityIndex) => (
            <Checkbox
              key={facility.name}
              className="facility"
              dataCy={`${dataCyPrefix}__service`}
              id={`store-facility-${facilityIndex}`}
              label={facility.facingName}
              check={facility.active}
              onChecked={() => {
                handleOnClick(facility, store)
                window.costaDataLayer = window.costaDataLayer || []
                window.costaDataLayer.filterName = facility.facingName
                window.costaDataLayer.filterState = facility.active ? 'enabled' : 'disabled'
                window._satellite && window._satellite.track('filter-toggle')
              }}
              invalid={invalid}
              clear={app.clear}
            />
          ))
          return (
            <Fragment key={store.name}>
              <Checkbox
                key={store.name}
                dataCy={`${dataCyPrefix}__type`}
                id={`store-type-${storeTypeIndex}`}
                label={store.label || store.name}
                clear={app.clear}
                onChecked={() => {
                  handleOnClick(store)
                  window.costaDataLayer = window.costaDataLayer || []
                  window.costaDataLayer.filterName = store.label || store.name
                  window.costaDataLayer.filterState = store.active ? 'enabled' : 'disabled'
                  window._satellite && window._satellite.track('filter-toggle')
                }}
                invalid={invalid}
                check={store.active}
              />

              {facilitiesCheckbox}
            </Fragment>
          )
        })}
      </StoreFiltersCheckboxes>

      <StoreFiltersApplyWrapper>
        <PrimaryButton
          dataCy={`${dataCyPrefix}__apply`}
          label={applyBtnLabel}
          disabled={false}
          backgroundColor={color.costaRed}
          contentColor={color.white}
          onClick={handleClose}
        />
      </StoreFiltersApplyWrapper>
      {state.stores.storesSet.length === 0 && (
        <NoResultsMessage data-cy={`${dataCyPrefix}__no-results-banner`}>
          {/* TODO this should be authorable */}
          <p>{noResults}</p>
        </NoResultsMessage>
      )}
    </StoreFiltersWrapper>
  )
}

StoreFilters.propTypes = {
  clearBtnLabel: PropTypes.string.isRequired,
  closeBtnLabel: PropTypes.string.isRequired,
  storeFiltersHeading: PropTypes.string.isRequired,
  storeFiltersLegend: PropTypes.string.isRequired,
  applyBtnLabel: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChecked: PropTypes.func.isRequired,
}

const StoreFiltersWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding: 0 12px;
  width: 100%;
  z-index: 2;
  background: ${color.white};
  height: 100%;

  ${above.tablet`
    position: relative;
    width: 449px;
    padding: 0 21px;
  `};
`

const StoreFiltersHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${color.greyLight};
  padding: 28px 8px 18px 8px;

  ${above.tablet`
    padding: 31px 19px 19px 19px;
  `}
`

const NoResultsMessage = styled.div`
  height: 46px;
  min-width: 320px;
  width: 100%;
  z-index: 1;
  left: 0;
  bottom: 0;
  background-color: ${color.lightRed};
  position: fixed;
  display: flex;
  align-items: center;

  ${above.tablet`
    width: 449px;
  `}

  p {
    ${costaText};
    margin: auto;
    color: ${color.white};
  }
`

const StoreFiltersHeading = styled.h3`
  text-align: center;
  color: ${color.greyDarker};
  font-size: 25px;
  margin: 0;
  ${above.tablet`
    font-size: 30px;
  `}
`

const StoreFiltersCheckboxes = styled.fieldset`
  padding: 0;
  border: none;

  legend {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }

  div {
    padding: 11px 6px 15px 6px;
    border-bottom: 1px solid ${color.greyLight};

    label {
      font-size: 18px;
      line-height: 26px;
      top: 3px;
      ${costaText};

      ${above.tablet`
        top: 6px;
        font-size: 20px;
        line-height: 26px;
	    `}
    }

    ${above.tablet`
      padding: 13px 18px 21px 18px;  
    `}
  }

  .facility {
    padding-left: 30px;

    ${above.tablet`
      padding-left: 40px;
    `}
  }
`

const StoreFiltersApplyWrapper = styled.div`
  margin: 39px auto 36px;

  button {
    margin: 0 auto;
  }
`

const CloseButton = styled(IconButton)`
  margin-right: 4px;
  img {
    height: 20px;
    vertical-align: middle;
  }
`

export default StoreFilters
