import React from 'react'
import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { color, above, costaTextBold } from '../../styles'
import ContentfulLink from './cwGatsbyComponents/link'
import CoreContentfulLink from './coreComponents/link'
import isRtl from '../../helpers/rtl'

function LinkCta({
  dataCy,
  internalLink,
  title,
  style,
  alignment,
  customColor,
  className,
  externalLink,
  appLinkUrl,
  assetLink,
  assetSlug,
  anchor,
  accessibleDescription,
  children,
}) {
  const buttonColour = customColor?.toLowerCase()
  const link = {
    externalLink,
    appLinkUrl,
    internalLink,
    assetLink,
    title,
    assetSlug,
    anchor,
    accessibleDescription,
    customColor,
    style,
    alignment,
    children,
  }

  return (
    <StyledLinkCta
      className={className}
      data-cy={dataCy}
      modifiers={[style || 'button', alignment || 'left']}
      customColor={buttonColour}
      customLinkColor={buttonColour}
      locale={link.node_locale}
    >
      {process.env.GATSBY_REPO === 'core' ? (
        <CoreContentfulLink link={link} customTitle={title} locale={link.node_locale} />
      ) : (
        <ContentfulLink link={link} customTitle={title} locale={link.node_locale} />
      )}
    </StyledLinkCta>
  )
}

const MODIFIER_CONFIG = {
  center: () => 'text-align: center',
  left: () => 'text-align: left',
  right: () => 'text-align: right',
  button: ({ customColor }) => `
    a {
      pointer-events: auto;
      background: ${customColor === 'white' ? color.white : color.costaRed};
      color: ${customColor === 'white' ? color.costaRed : color.white};
      padding: 15px 20px;
      border-radius: 32px;

      ${above.tablet`
        padding: 18px 27px;
      `}

      &:hover {
        background: ${color.lightRed};
        color: ${color.white};
        &:after {
          border-left: 6px solid ${color.white};
        }
      }

      &:after {
        border-left: 6px solid ${customColor === 'white' ? color.costaRed : color.white};
      }
    }
  `,

  link: ({ customLinkColor }) => `
    a {
      color: ${customLinkColor === 'white' ? color.white : color.costaRed};
      padding: 15px 0;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;

      ${above.tablet`
        padding: 18px 0;
      `}

      &:after {
        border-left: 6px solid ${customLinkColor === 'white' ? color.white : color.costaRed};
      }

      &:hover {
        color: ${color.lightRed};
        &:after {
        border-left: 6px solid ${color.lightRed};
      }
      }
    }
  `,
}

const StyledLinkCta = styled.div`
  a {
    ${costaTextBold}
    display: inline-block;
    letter-spacing: 1px;
    cursor: pointer;
    margin-bottom: 16px;

    ${above.tablet`
      margin-bottom: 18px;
    `}

    &:after {
      content: '';
      display: inline-block;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;

      ${above.tablet`
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      `}

      ${({ locale }) =>
        isRtl(locale)
          ? `
        margin-right: 16px;
        transform: rotate(180deg);
      `
          : `
        margin-left: 16px;
      `}
    }
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`

export default LinkCta
