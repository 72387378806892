const rtlEnvs = ['ar-']

const isRtl = locale => {
  if (rtlEnvs.find(language => locale?.includes(language))) {
    return true
  }
  return false
}

module.exports = isRtl
