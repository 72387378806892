/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import StoreCardClosed from '../StoreCardClosed/storeCardClosed'
import IconButton from '../elements/Button/IconButton/iconButton'
import mobileDraggable from '../assets/icons/storeLocator/mobileDraggable.svg'
import { color, above } from '../styles'
import { getDistance, addressStringMaker, getWalkingTime, storeMarker } from '../helpers/stores'

function StoreCardList({
  showWalkingDistance,
  storeOpenText,
  opensText,
  closesText,
  storeClosedText,
  openTomorrowText,
  store24HoursText,
  storeOpen24HoursText,
  storeTimesFormat,
  user,
  unit,
  stores,
  chevronAlt,
  storeClick,
  walkText,
  draggable,
  draggableClick,
  draggableIconAlt,
  draggableAriaLabel,
  dataCyScreenSize,
  mondayText,
  tuesdayText,
  wednesdayText,
  thursdayText,
  fridayText,
  saturdayText,
  sundayText,
}) {
  return (
    <Wrapper>
      {draggable && (
        <DraggableIconButton
          icon={mobileDraggable}
          iconAlt={draggableIconAlt}
          ariaLabel={draggableAriaLabel}
          dataCy="mobile-draggable"
          onClick={draggableClick}
        />
      )}
      <StoreCardListWrapper data-cy={`store-card-list-${dataCyScreenSize}`}>
        {stores.length &&
          stores.map((store, index) => {
            const {
              storeNo8Digit,
              storeType,
              storeTypeIcon,
              storeTypeIconTitle,
              storeAddress,
              storeNameExternal,
              storeOperatingHours,
              storeFacilities,
              latitude,
              longitude,
              duration,
              distance,
            } = store

            return (
              <li key={storeNo8Digit}>
                <StoreCardClosed
                  index={index}
                  chevronAlt={chevronAlt}
                  icon={
                    process.env.GATSBY_BUDDY_PIPELINE === 'uk'
                      ? storeMarker(storeType)
                      : storeTypeIcon
                  }
                  iconAlt={storeTypeIconTitle || ''}
                  heading={storeNameExternal}
                  address={addressStringMaker(storeAddress)}
                  distance={getDistance(distance, unit)}
                  facilities={storeFacilities}
                  storeClick={storeClick}
                  store={store}
                  hours={storeOperatingHours}
                  storeTimesFormat={storeTimesFormat}
                  storeOpenText={storeOpenText}
                  storeClosedText={storeClosedText}
                  opensText={opensText}
                  openTomorrowText={openTomorrowText}
                  store24HoursText={store24HoursText}
                  storeOpen24HoursText={storeOpen24HoursText}
                  closesText={closesText}
                  mondayText={mondayText}
                  tuesdayText={tuesdayText}
                  wednesdayText={wednesdayText}
                  thursdayText={thursdayText}
                  fridayText={fridayText}
                  saturdayText={saturdayText}
                  sundayText={sundayText}
                  duration={duration ? getWalkingTime(duration.value) : ''}
                  showWalkingDistance={showWalkingDistance}
                  user={user}
                  latitude={latitude}
                  longitude={longitude}
                  walkText={walkText}
                  dataCyScreenSize={dataCyScreenSize}
                  type={storeType}
                />
              </li>
            )
          })}
      </StoreCardListWrapper>
    </Wrapper>
  )
}

const StoreCardListWrapper = styled.ul`
  ${above.tablet`
    padding-top: 20px;
  `}

  li {
    &::after {
      content: '';
      display: block;

      border-bottom: 1px solid ${color.greyLight};
      margin: 0px 12px;

      ${above.tablet`
        margin: 0px 20px;
      `}
    }
  }
`

const DraggableIconButton = styled(IconButton)`
  margin: 16px auto 14px;
  pointer-events: all;
  height: 4px;
  display: flex;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: ${color.white};

  ${above.tablet`
    position: relative;
  `}
`

StoreCardList.defaultProps = {
  showWalkingDistance: true,
}

StoreCardList.propTypes = {
  showWalkingDistance: PropTypes.bool,
  storeOpenText: PropTypes.string.isRequired,
  opensText: PropTypes.string.isRequired,
  closesText: PropTypes.string.isRequired,
  storeClosedText: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
  stores: PropTypes.array.isRequired,
}

export default StoreCardList
