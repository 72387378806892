import React from 'react'
import styled, { css } from 'styled-components'
import { above, color, costaDisplayWaveBold } from 'ui/src/styles/index'

function BreakoutFactsAndBenefits({ data }) {
  const { icon, colouredText, textColour } = data
  return (
    <Wrapper textColor={textColour || color.burntOrange}>
      {icon.file.contentType === 'image/svg+xml' && icon?.svg?.content ? (
        // render SVG files inline to allow use of fill
        <SvgIconWrapper dangerouslySetInnerHTML={{ __html: icon.svg.content }} />
      ) : (
        <ImageIcon src={icon.file.url} alt="icon" />
      )}
      <Text>{colouredText}</Text>
    </Wrapper>
  )
}

const Text = styled.p`
  ${costaDisplayWaveBold}
  font-size: 20px;
  line-height: 1.1;
  margin: 0;

  ${above.tablet`
    font-size: 30px;
  `}
`

const sharedIconStyles = css`
  display: flex;
  justify-content: center;
  max-height: 52px;

  ${above.tablet`
    max-height: 80px;
    margin-bottom: 32px;
  `}
`

const SvgIconWrapper = styled.div`
  ${sharedIconStyles}
  margin-bottom: 24px;

  svg {
    width: 100%;
    fill: currentColor;
  }
`

const ImageIcon = styled.img`
  ${sharedIconStyles}
  margin: 0 auto;
`

const Wrapper = styled.article`
  text-align: center;
  width: 79%;
  margin: 72px auto;
  max-width: 803px;
  color: ${props => props.textColor};

  ${above.tablet`
    width: 72%;
    margin: 80px auto;
  `}

  ${above.desktop`
    width: 100%;
  `}
`

export default BreakoutFactsAndBenefits
