import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import sanitize from 'sanitize-filename'
import { usePageContext } from 'utils/src/pageContext'
import AppLinks from '../../../AppLinks/appLinks'

export default function ContentfulLink({ link = {}, className, ariaLabel, onClick }) {
  const {
    externalLink,
    appLinkUrl,
    internalLink,
    assetLink,
    assetSlug,
    anchor,
    accessibleDescription,
    title,
  } = link
  const { allPages } = usePageContext()
  const commonProps = {
    className,
    title: accessibleDescription,
    onClick,
    'aria-label': ariaLabel,
  }

  if (internalLink) {
    const linkTo = `/${allPages[internalLink.id]}`.replace('//', '/')
    return (
      <GatsbyLink to={linkTo} {...commonProps}>
        {title}
      </GatsbyLink>
    )
  }

  if (assetLink) {
    const slug = assetSlug
      ? `${sanitize(assetSlug).replace(/ /g, '-')}.pdf`
      : assetLink.file.fileName
    const prefix = [undefined, '/'].includes(process.env.GATSBY_PATH_PREFIX)
      ? ''
      : `/${process.env.GATSBY_PATH_PREFIX}`
    return (
      <a href={`${prefix}/docs/${slug}`} target="_blank" rel="noreferrer" {...commonProps}>
        {link.children}
      </a>
    )
  }

  if (externalLink) {
    return (
      <a href={externalLink} target="_blank" rel="noreferrer" {...commonProps}>
        {link.children}
      </a>
    )
  }

  if (appLinkUrl) {
    return <AppLinks url={appLinkUrl} linkTitle={accessibleDescription} />
  }

  if (anchor) {
    return (
      <a href={`#${anchor}`} {...commonProps}>
        {link.children}
      </a>
    )
  }

  return null
}
