/* eslint-disable react/sort-comp */
import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './select.module.css'

export default class Select extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
    customStyles: PropTypes.string,
    changeEvent: PropTypes.func,
    onFocusMethod: PropTypes.func,
    errorStyle: PropTypes.string,
    selectedIndex: PropTypes.number,
    heading: PropTypes.string,
    description: PropTypes.string,
    dataCy: PropTypes.string,
    error: PropTypes.bool,
  }

  static defaultProps = {
    items: [''],
    customStyles: '',
    changeEvent: () => {},
    onFocusMethod: () => {},
    errorStyle: '',
    selectedIndex: 0,
    heading: '',
    description: '',
    dataCy: '',
    error: false,
  }

  state = {
    open: false,
    selectedItem: this.props.items[this.props.selectedIndex], //eslint-disable-line
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    // Adding this because of the build error:
    // WebpackError: ReferenceError: document is not defined
    if (typeof document !== 'undefined') {
      document.addEventListener('mousedown', this.handleClick, false)
      document.addEventListener('touchstart', this.handleClick, false)
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedIndex, items } = this.props
    if (selectedIndex !== prevProps.selectedIndex) {
      this.updateSelectedItem(items[selectedIndex])
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
    document.removeEventListener('touchstart', this.handleClick, false)
  }

  handleClick = e => {
    const { open } = this.state

    if (!this.node.contains(e.target) && open) {
      this.toggle(false)
    }
  }

  updateSelectedItem = item => {
    const { changeEvent } = this.props

    this.setState({ selectedItem: item.replace(/(,\s)+/g, ', ') })
    changeEvent(item)
  }

  toggle = nextState => {
    const { open } = this.state
    this.setState({ open: nextState || !open })
  }

  render() {
    const { open, selectedItem } = this.state
    const { items, customStyles, errorStyle, onFocusMethod, heading, description, dataCy, error } =
      this.props
    const [initialItem] = items
    return (
      <div data-cy={dataCy} data-cy-error={error}>
        {heading && <p className={styles.heading}>{heading}</p>}
        {description && <p className={styles.description}>{description}</p>}
        <div
          className={`${styles.container} ${customStyles || ''}`}
          // eslint-disable-next-line no-return-assign
          ref={node => (this.node = node)}
        >
          <div className={` ${errorStyle || ''} ${styles.select} ${(open && styles.open) || ''}`}>
            <button
              onClick={this.toggle}
              type="button"
              className={`${styles.selected} ${
                (selectedItem === initialItem && styles.emptyDropdown) || ''
              }`}
              onFocus={() => onFocusMethod('selectdropdown')}
            >
              {selectedItem}
            </button>
            <div
              className={`${(!open && styles.hidden) || ''} ${(errorStyle && styles.error) || ''} `}
            >
              {items.map(item => (
                <button
                  className={(item === initialItem && styles.initialItem) || ''}
                  type="button"
                  onClick={() => {
                    this.toggle(false)
                    this.updateSelectedItem(item)
                  }}
                  key={item}
                >
                  {item.replace(/(,\s)+/g, ', ')}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
