/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { above, color } from '../../../styles'

function CheckboxWithLabel({
  className,
  dataCy,
  onChecked,
  clear,
  check,
  id,
  invalid,
  label,
  labelRight,
}) {
  const [checked, setChecked] = useState(false)
  const onChange = () => {
    onChecked()
    setChecked(check === undefined ? !checked : check)
  }

  useEffect(() => {
    setChecked(clear ? false : checked)
  }, [clear])

  return (
    <CheckboxWrapper
      className={className}
      data-cy={`${dataCy}`}
      data-cy-id={`${dataCy}-${label.toLowerCase().replace(/\s/g, '-')}`}
      data-cy-selected={check ? 'true' : 'false'}
      checked={check !== undefined ? check : clear ? false : checked}
      invalid={!checked && invalid}
      labelRight={labelRight}
      data-testid={`${dataCy}`}
    >
      <input
        onChange={onChange}
        checked={check !== undefined ? check : clear ? false : checked}
        type="checkbox"
        id={id}
      />
      <label htmlFor={id}>
        {label}
        <span
          tabIndex={0}
          onKeyDown={e => {
            // enable enter key on checkbox
            if (e.key === 'Enter') {
              setChecked(!checked)
            }
          }}
        />
      </label>
    </CheckboxWrapper>
  )
}

CheckboxWithLabel.defaultProps = {
  labelRight: false,
}

CheckboxWithLabel.propTypes = {
  dataCy: PropTypes.string.isRequired,
  onChecked: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  labelRight: PropTypes.bool,
}

const CheckboxWrapper = styled.div`
  ${above.tablet`
    border-radius: 4px;
  `};
  input {
    display: none;
  }

  span {
    &::before {
      content: '';
      width: 0px;
      height: 2px;
      border-radius: 2px;
      background: ${color.white};
      position: absolute;
      transform: rotate(45deg);
      top: 8px;
      left: 5px;
      transition: width 50ms ease 50ms;
      transform-origin: 0% 0%;

      ${above.mobile`
        top: 10px;
        left: 7px;
	    `}
    }

    &::after {
      content: '';
      width: 0;
      height: 2px;
      border-radius: 4px;
      background: ${color.white};
      position: absolute;
      transform: rotate(305deg);
      top: 12px;
      left: 6px;
      transition: width 50ms ease;
      transform-origin: 0% 0%;

      ${above.mobile`
        top: 14px;
        left: 8px;
	    `}
    }
  }

  input + label {
    display: flex;
    flex-direction: ${({ labelRight }) => (labelRight === true ? 'row-reverse' : 'row')};
    color: ${color.greyDarker};
    position: relative;
    cursor: pointer;
    span {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 2px;
      border: 2px solid
        ${({ invalid, checked }) =>
          invalid ? color.lightRed : checked ? color.costaRed : color.greyCore};
      background: transparent;
      transition: background-color 0.1s ease-in-out;
      position: absolute;
      cursor: pointer;
      z-index: 1;

      ${({ labelRight }) => (labelRight === true ? 'left: 0px;' : 'right: 0px;')};

      ${above.mobile`
		      width: 24px;
          height: 24px;
	    `}
    }
  }

  input:checked + label {
    span {
      background-color: ${color.costaRed};
    }
    span::before {
      width: 5px;
      transition: width 50ms ease;
    }
    span::after {
      width: 11px;
      transition: width 50ms ease 50ms;
    }
  }
`

export default CheckboxWithLabel
