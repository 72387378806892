import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import headerLanguageTags from 'utils/src/headerLanguageTags'
import { renderContentBlocks } from '../utils/content-blocks'
import AnalyticsDataLayer from '../utils/analyticsDataLayer'
import Layout from '../components/layout'
import useWebsiteSettings from '../utils/build-helpers/useWebsiteSettings'

export const PageQuery = graphql`
  query ($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      contentful_id
      locale: node_locale
      title
      description
      slug
      noindex
      hero {
        ...FragmentHero
      }
      secondaryNavigation {
        ...FragmentNavigationGroup
      }
      contentBlocks {
        ... on Node {
          ... on ContentfulBlockBreakoutFactBenefits {
            ...FragmentBlockBreakoutFactBenefits
          }
          ... on ContentfulBlockPromo {
            ...Promo
          }
          ... on ContentfulBlockRichText {
            ...FragmentBlockRichText
          }
          ... on ContentfulBlockStoreLocatorMap {
            ...BlockStoreLocatorMap
          }
          ... on ContentfulBlockPromoGridCollection {
            ...FragmentBlockPromoGridCollection
          }
          ... on ContentfulBlockAccordion {
            ...FragmentBlockAccordion
          }
          ... on ContentfulNormalisedLink {
            ...NormalisedCoreLink
          }
          ... on ContentfulStaticComponent {
            ...StaticComponent
          }
        }
      }
    }
  }
`

function Page({ data, pageContext, location }) {
  const { locale } = pageContext
  const page = data.contentfulPage
  const contentBlocks = page.contentBlocks || []

  const { noindex: websiteNoindex } = useWebsiteSettings()

  const noindex = websiteNoindex === 'noindex' || page.noindex === 'noindex'
  const isStoreLocator =
    page.contentBlocks?.filter(e => e.__typename === 'ContentfulBlockStoreLocatorMap').length !== 0

  const oneTrustProdDataDomain = process.env.GATSBY_ONETRUST_PROD_DATA_DOMAIN
  const oneTrustTestDataDomain = process.env.GATSBY_ONETRUST_TEST_DATA_DOMAIN

  const loadOneTrust = process.env.GATSBY_LOAD_ONETRUST === 'true'
  const isProd = process.env.GATSBY_DEPLOY_ENVIRONMENT === 'production'

  const prodScripts = [
    <script
      type="text/javascript"
      src={`https://cdn-ukwest.onetrust.com/consent/${oneTrustProdDataDomain}/OtAutoBlock.js`}
    />,
    <script
      src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      data-domain-script={oneTrustProdDataDomain}
    />,
    <script type="text/javascript">{'function OptanonWrapper() {}'}</script>,
  ]

  const testScripts = [
    <script
      type="text/javascript"
      src={`https://cdn-ukwest.onetrust.com/consent/${oneTrustTestDataDomain}/OtAutoBlock.js`}
    />,
    <script
      src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      data-domain-script={oneTrustTestDataDomain}
    />,
    <script type="text/javascript">{'function OptanonWrapper() {}'}</script>,
  ]

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="description" content={page.description} />
        <html lang={locale} />
        <meta name="robots" content={noindex ? 'noindex' : 'all'} />
        {loadOneTrust && isProd ? [...prodScripts] : null}
        {loadOneTrust && !isProd ? [...testScripts] : null}
      </Helmet>
      {process.env.GATSBY_BUDDY_PIPELINE !== 'uk' && (
        <AnalyticsDataLayer title={page.title} location={location} />
      )}
      {page.slug === '/' && (
        <Helmet>
          {headerLanguageTags?.map(({ url, locale: langLocale }) => (
            <link rel="alternate" href={url} hrefLang={langLocale} key={langLocale} />
          ))}
        </Helmet>
      )}

      <Layout
        hero={page.hero}
        secondaryNavigation={page.secondaryNavigation}
        title={page.title}
        location={location}
        isStoreLocator={isStoreLocator}
        noBackground={isStoreLocator}
      >
        {contentBlocks.map(component =>
          renderContentBlocks({
            component,
          })
        )}
      </Layout>
    </div>
  )
}

export default Page
