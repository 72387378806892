import React, { useEffect } from 'react'

function OneTrustCookieList() {
  useEffect(() => {
    window.OneTrust?.initializeCookiePolicyHtml()
  }, [])
  return <div id="ot-sdk-cookie-policy" />
}

export default OneTrustCookieList
