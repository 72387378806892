/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { getMicrocopyObject, useMicrocopy } from 'utils/src/microcopy'
/* @ts-ignore-next-line */
import { BlockPromo } from 'cw-gatsby-core/src/components'
import { Button, Checkbox, Input, Select, Form, FormField, Heading } from 'ui/src'
import PopupCTA from 'ui/src/PopupCTA/popupCTA'
import WinnerPromo from './FCMGWinnerPromo'
import ReceiptField from './ReceiptField'
import { uploadReceipt } from './FMCGapi'
import suite from './validation'
import { useFMCGLoserPromo } from './FMCGLoserPromoQuery'

export type Inputs = {
  firstName: string
  surname: string
  email: string
  telephone: string
  country: string
  receipt: File | null
  terms: boolean
  age: boolean
}

const countries = [
  'Poland',
  'Bulgaria',
  'Hungary',
  'Czech Republic',
  'Slovakia',
  'Ireland',
  'Greece',
  'Croatia',
  'Slovenia',
  'Romania',
  'Switzerland',
  'Austria',
  'Serbia',
]

const countryOptions = countries.map(c => ({ label: c, value: c }))

function FMCGRegistrationForm({ node_locale }: { node_locale: string }) {
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [showParticipationPopup, setShowParticipationPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loserPromo, setLoserPromo] = useState<object | null>(null)
  const [winnerPromo, setWinnerPromo] = useState<{
    prizeName: string
    shortDescription: string
    redemptionDescription: string
    image: string
  } | null>(null)

  const microcopy = getMicrocopyObject(useMicrocopy(node_locale))

  const contentfulLoserPromo = useFMCGLoserPromo(node_locale)

  const [inputs, setInputs] = React.useState<Inputs>({
    firstName: '',
    surname: '',
    email: '',
    telephone: '',
    country: '',
    receipt: null,
    terms: false,
    age: false,
  })

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const result = suite(inputs, microcopy) // validate form
    setInputs({ ...inputs }) // force re-render to show errors

    if (!result.isValid()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      const fmcgResponse = await uploadReceipt(inputs)

      if (
        fmcgResponse.status >= 400 ||
        !fmcgResponse.data ||
        fmcgResponse.data.errorCode > 1 ||
        fmcgResponse.data.errCode
      ) {
        setShowErrorPopup(true)
      } else if (fmcgResponse.data.errorCode === 1 && !fmcgResponse.data.errCode) {
        setShowParticipationPopup(true)
      } else if (fmcgResponse.data.instantWinResult.winner) {
        const prize = fmcgResponse.data.instantWinResult.redeemedPrize
        setWinnerPromo({
          prizeName: prize.name,
          shortDescription: prize.shortDescription,
          redemptionDescription: prize.redeemDescription,
          image: prize.imgUrl,
        })
        window.scrollTo(0, 0)
      } else if (!fmcgResponse.data.instantWinResult.winner) {
        setLoserPromo(contentfulLoserPromo)
      }
    }

    setLoading(false)
  }

  const onFieldChange = (fieldName: string, value: any) => {
    suite.resetField(fieldName)
    setInputs({ ...inputs, [fieldName]: value })
  }

  const validationResult = suite.get()

  if (loserPromo) {
    return <BlockPromo data={loserPromo} />
  }

  if (winnerPromo) {
    return (
      <WinnerPromo
        image={winnerPromo.image}
        congratulations={microcopy.fmcgRegistrationForm.winnerPromoCongratulationsText}
        prizeName={winnerPromo.prizeName}
        prizeDescription={winnerPromo.shortDescription}
        redemptionDescription={winnerPromo.redemptionDescription}
      />
    )
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Heading dataCy="heading">{microcopy.fmcgRegistrationForm.heading}</Heading>

        <FormField>
          <Input
            id="firstName"
            label={microcopy.fmcgRegistrationForm.firstNameLabel}
            placeholder={microcopy.fmcgRegistrationForm.firstNamePlaceholder}
            value={inputs.firstName}
            error={validationResult.getErrors('firstName')[0]}
            type="text"
            dataCy="input__first-name"
            onChange={value => onFieldChange('firstName', value)}
          />
        </FormField>

        <FormField>
          <Input
            id="surname"
            label={microcopy.fmcgRegistrationForm.surnameLabel}
            placeholder={microcopy.fmcgRegistrationForm.surnamePlaceholder}
            value={inputs.surname}
            error={validationResult.getErrors('surname')[0]}
            type="text"
            dataCy="input__surname"
            onChange={value => onFieldChange('surname', value)}
          />
        </FormField>

        <FormField>
          <Input
            id="email"
            label={microcopy.fmcgRegistrationForm.emailLabel}
            placeholder={microcopy.fmcgRegistrationForm.emailPlaceholder}
            value={inputs.email}
            error={validationResult.getErrors('email')[0]}
            type="email"
            dataCy="input__email"
            onChange={value => onFieldChange('email', value)}
          />
        </FormField>

        <FormField>
          <Input
            id="telephone"
            label={microcopy.fmcgRegistrationForm.telephoneLabel}
            placeholder={microcopy.fmcgRegistrationForm.telephonePlaceholder}
            value={inputs.telephone}
            error={validationResult.getErrors('telephone')[0]}
            type="tel"
            dataCy="input__telephone"
            onChange={value => onFieldChange('telephone', value)}
          />
        </FormField>

        <FormField>
          <Select
            id="country_of_residence"
            label={microcopy.fmcgRegistrationForm.countryLabel}
            dataCy="select__country-of-residence"
            placeholder={microcopy.fmcgRegistrationForm.countryPlaceholder}
            value={inputs.country}
            error={validationResult.getErrors('country')[0]}
            options={countryOptions}
            onChange={value => onFieldChange('country', value)}
          />
        </FormField>

        <FormField>
          <ReceiptField
            id="receipt"
            label={microcopy.fmcgRegistrationForm.receiptLabel}
            dataCy="input__receipt"
            value={inputs.receipt}
            error={validationResult.getErrors('receipt')[0]}
            buttonTextSelect={microcopy.fmcgRegistrationForm.receiptButtonTextSelect}
            buttonTextReplace={microcopy.fmcgRegistrationForm.receiptButtonTextReplace}
            onChange={file => onFieldChange('receipt', file)}
          />
        </FormField>

        {microcopy.fmcgRegistrationForm.termsAndConditionsCheckboxLabel && (
          <FormField>
            <Checkbox
              id="terms"
              value={inputs.terms}
              error={validationResult.getErrors('terms')[0]}
              dataCy="input__terms"
              onChange={checked => onFieldChange('terms', checked)}
            >
              {microcopy.fmcgRegistrationForm.termsAndConditionsCheckboxLabel}
            </Checkbox>
          </FormField>
        )}

        {microcopy.fmcgRegistrationForm.ageConfirmationCheckboxLabel && (
          <FormField>
            <Checkbox
              id="age"
              value={inputs.age}
              error={validationResult.getErrors('age')[0]}
              dataCy="input__age"
              onChange={checked => onFieldChange('age', checked)}
            >
              {microcopy.fmcgRegistrationForm.ageConfirmationCheckboxLabel}
            </Checkbox>
          </FormField>
        )}

        <FormField>
          <StyledButton variant="solid" type="submit" icon="submit" loading={loading}>
            {microcopy.submitButtonText}
          </StyledButton>
        </FormField>
      </Form>

      <PopupCTA
        content={
          <div>
            <header>
              <h1>{microcopy.fmcgRegistrationForm.errorPopupHeader}</h1>
            </header>
            <p>{microcopy.fmcgRegistrationForm.errorPopupText}</p>
          </div>
        }
        buttonValue={microcopy.fmcgRegistrationForm.errorPopupButtonText}
        click={() => {
          setShowErrorPopup(false)
        }}
        icon="back"
        visible={showErrorPopup}
        customStyles="popupHeader"
        type="button"
      />

      <PopupCTA
        content={
          <div>
            <header>
              <h1>{microcopy.fmcgRegistrationForm.errorPopupParticipationHeader}</h1>
            </header>
            <p>{microcopy.fmcgRegistrationForm.errorPopupParticipationText}</p>
          </div>
        }
        buttonValue={microcopy.fmcgRegistrationForm.errorPopupButtonText}
        click={() => {
          setShowParticipationPopup(false)
        }}
        icon="back"
        visible={showParticipationPopup}
        customStyles="popupHeader"
        type="button"
      />
    </>
  )
}

const StyledButton = styled(Button)`
  margin: 0 auto;
`

export default FMCGRegistrationForm
