/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { usePageContext } from 'utils/src/pageContext'

import { above, color } from 'ui/src/styles/index'
import { renderRichText } from '../../utils/richText'
import LinkCta from '../linkCta/linkCta'
import brandColour from '../../helpers/brandColours'

const PromoGridPrimaryButton = ({
  image,
  content,
  backgroundColour,
  brandStyleBgColour,
  headingTextColour,
  textColour,
  ctaColour,
  mobileImage,
  linkCTA,
  ctaStyle,
}) => {
  const brandBgColour = brandColour(brandStyleBgColour)

  const bodyTextColour = textColour && textColour === 'White' ? color.white : color.greyDarker
  const titleTextColour =
    headingTextColour && headingTextColour === 'White' ? color.white : color.costaRed
  const ctaBackgroundColour = ctaColour && ctaColour === 'White' ? color.white : color.costaRed
  const ctaBackgroundHoverColour =
    ctaColour && ctaColour === 'White' ? color.offWhite : color.lightRed
  const ctaTextColour = ctaColour && ctaColour === 'White' ? color.costaRed : color.white
  const styleOfCta = ctaStyle && ctaStyle === 'link' ? 'link' : 'button'

  const { allPages } = usePageContext()
  const withPrimaryButton = () => (
    <Wrapper data-cy="primary-cta__grid-promo" backgroundColour={backgroundColour ?? brandBgColour}>
      {image.file.contentType.includes('svg') ? (
        <SvgWrapper>
          <SVGImage src={image.file.url} />
        </SvgWrapper>
      ) : (
        <Image
          data-cy="primary-cta__image"
          image={image}
          mobileImage={mobileImage}
          alt={image.description}
        />
      )}
      <Content headingTextColour={titleTextColour} textColour={bodyTextColour}>
        {renderRichText(content, allPages)}
      </Content>
      <LinkCta
        link={linkCTA}
        customTitle={linkCTA.title}
        customColor={ctaBackgroundColour}
        customLinkColor={ctaBackgroundColour}
        ctaBackgroundHoverColour={ctaBackgroundHoverColour}
        ctaTextColour={ctaTextColour}
        ctaStyle={styleOfCta}
        alignment="center"
      >
        {linkCTA.title}
      </LinkCta>
    </Wrapper>
  )

  const withNoButton = () => (
    <Wrapper data-cy="primary-cta__grid-promo" backgroundColour={backgroundColour ?? brandBgColour}>
      {image.file.contentType.includes('svg') ? (
        <SvgWrapper>
          <SVGImage src={image.file.url} />
        </SvgWrapper>
      ) : (
        <Image
          data-cy="primary-cta__image"
          image={image}
          mobileImage={mobileImage}
          alt={image.description}
        />
      )}
      <Content headingTextColour={titleTextColour} textColour={bodyTextColour}>
        {renderRichText(content, allPages)}
      </Content>
    </Wrapper>
  )

  return linkCTA ? withPrimaryButton() : withNoButton()
}

PromoGridPrimaryButton.defaultProps = {
  backgroundColour: '',
  ctaColour: '',
  brandStyleBgColour: '',
  headingTextColour: '',
  textColour: '',
  linkCTA: null,
  ctaStyle: '',
}

PromoGridPrimaryButton.propTypes = {
  image: PropTypes.object.isRequired,
  content: PropTypes.shape({ raw: PropTypes.string }).isRequired,
  backgroundColour: PropTypes.string,
  brandStyleBgColour: PropTypes.string,
  headingTextColour: PropTypes.string,
  textColour: PropTypes.string,
  ctaColour: PropTypes.string,
  mobileImage: PropTypes.object.isRequired,
  linkCTA: PropTypes.shape({
    link: PropTypes.object,
    title: PropTypes.string,
    style: PropTypes.string,
    alignment: PropTypes.string,
  }),
  ctaStyle: PropTypes.string,
}

const Wrapper = styled.div`
  background-color: ${p => p.backgroundColour};
  margin: 0 auto;
  ${above.tablet`
    flex-wrap: no-wrap;
    justify-content: center;
  `}
`

const Image = styled.div.attrs(props => ({
  alt: props.image.description,
}))`
  height: 242px;
  width: 320px;
  background-image: ${({ mobileImage }) => `url(${mobileImage?.file.url})`};
  background-size: cover;
  background-position: center;

  ${above.tablet`
    background-image: ${({ image }) => `url(${image?.file.url})`};
    height: 180px;
    width: 240px;
  `}

  ${above.tabletLarge`
    width: 320px;
    height: 241px;
  `}

  ${above.desktop`
    height: 248px;
    width: 330px;
  `}
`

const SVGImage = styled.img`
  margin: auto;
  height: 72px;

  ${above.tablet`
  height: 100px;
`}
`

const SvgWrapper = styled.div`
  display: flex;
  height: 242px;
  width: 320px;

  ${above.tablet`
  height: 180px;
  width: 240px;
`}

  ${above.tabletLarge`
  width: 320px;
  height: 241px;
`}

${above.desktop`
  height: 248px;
  width: 330px;
`}
`

const Content = styled.div`
  width: 320px;
  height: auto;
  text-align: center;
  color: ${p => p.textColour};

  ${above.tablet`
    width: 240px;
  `}

  ${above.tabletLarge`
    width: 320px;
  `}

  ${above.desktop`
    width: 330px;
  `}

  h1, h2, h3, h4, h5, h6 {
    color: ${p => p.headingTextColour};
  }

  h4 {
    margin: 21px 0 10px;
  }

  p {
    margin: 0 34px 36px;
    ${above.tablet`
      margin: 0 20px 35px;
    `}
  }
`

export default PromoGridPrimaryButton
