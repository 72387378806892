import React from 'react'
import styled from 'styled-components'
import { above } from './styles'

interface IProps {
  children: React.ReactNode
}

function FormField({ children }: IProps): React.ReactElement {
  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.div`
  margin: 24px 0;

  ${above.mobile`
    margin: 48px;
    max-width: 608px; 
  `}

  ${above.tablet`
    margin: 44px;
    max-width: 488px;
  `}

  ${above.tabletLarge`
    max-width: 446px;
  `}

  ${above.desktopLarge`
    margin: 35px;
    max-width: 448px;
  `}
`

export default FormField
