import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import { getMicrocopy, useMicrocopy } from 'utils/src/microcopy'
import StoreLocatorMap from '../../StoreLocatorMap/storeLocatorMap'
import StoreCard from '../../StoreCard/storeCard'
import { StoreLocatorContext } from '../../reducers/combineReducers'
import StoreFiltersClosed from '../../StoreFiltersClosed/storeFiltersClosed'
import StoreLocatorSearchBar from '../../StoreLocatorSearchBar/storeLocatorSearchBar'
import IconButton from '../../elements/Button/IconButton/iconButton'
import StoreCardList from '../../StoreCardList/storeCardList'
import { getUserLocation, createDirectionsLink } from '../../helpers/requestLocationHelpers'
import UserLocationButton from '../../UserLocationButton/userLocationButton'
import mobileDraggable from '../../assets/icons/storeLocator/mobileDraggable.svg'
import StoreCardClosed from '../../StoreCardClosed/storeCardClosed'
import StoreFilters from '../../StoreFilters/storeFilters'
import useStoreLocatorMap from '../../hooks/useStoreLocatorMap'
import SearchThisAreaContainer from '../../SearchThisAreaContainer/searchThisAreaContainer'
import StoreCardNoResults from '../../StoreCardNoResults/storeCardNoResults'
import {
  getDistance,
  addressStringMaker,
  getWalkingTime,
  getStores,
  storeMarker,
} from '../../helpers/stores'

import { color } from '../../styles'

function SmallStoreLocatorMapRegion({ className, locale }) {
  const urlFilter = typeof window !== 'undefined' ? new URL(window.location.href) : null
  const [state, dispatch] = useContext(StoreLocatorContext)
  const [showStoreCardList, setShowStoreCardList] = useState(false)
  const data = useMicrocopy(locale)
  const { stores, location, app } = state
  const { storeTypes, storeCardSecondButtonLink, storeTimesFormat } = useStoreLocatorMap(locale)
  const unit = getMicrocopy({ key: 'storeLocatorMap.DistanceUnit', data })
  const dataCyScreenSize = 'mobile'
  const searchBarInputName = 'mobileTextInput'

  // Check if the store locator search bar is focused and blur it if so
  const blurSearchInputOnDrag = () => {
    if (document.activeElement && document.activeElement !== document.body) {
      if (document.activeElement.id === searchBarInputName) {
        document.activeElement.blur()
      }
    }
  }

  return (
    <>
      <MobileOverlay>
        {!app.showFilters && !app.showStoreCard && !showStoreCardList && (
          <>
            <FiltersAndSearch>
              <FilterButton
                iconAlt={getMicrocopy({ key: 'storeLocatorMap.FilterButtonIconAlt', data })}
                btnAriaLabel={getMicrocopy({ key: 'storeLocatorMap.FilterButtonAriaLabel', data })}
                activeFiltersCount={app.activeFilterCount}
                disabled={false}
                handleFiltersClick={() => {
                  dispatch({ type: 'showFilters', payload: !app.showFilters })
                }}
                dataCyScreenSize={dataCyScreenSize}
              />
              <FullWidthSearchBar
                locale={locale}
                placeholder={getMicrocopy({
                  key: 'storeLocatorMap.SearchBarPlaceholder',
                  data,
                })}
                btnText=""
                btnAriaLabel={getMicrocopy({
                  key: 'storeLocatorMap.SearchBarButtonAriaLabel',
                  data,
                })}
                inputAriaLabel={getMicrocopy({
                  key: 'storeLocatorMap.SearchBarInputAriaLabel',
                  data,
                })}
                name={searchBarInputName}
                apiKey={process.env.GATSBY_GOOGLE_API_KEY}
                setLocation={locationToSet => {
                  urlFilter.searchParams.delete('filter')
                  window.history.replaceState(null, '', urlFilter.href)
                  dispatch({
                    type: 'setSelectedLocation',
                    payload: { lat: locationToSet.latitude, lng: locationToSet.longitude },
                  })
                  getStores({
                    lat: locationToSet.latitude,
                    lng: locationToSet.longitude,
                    locale,
                    unit,
                  }).then(results => {
                    dispatch({ type: 'clear' })
                    dispatch({ type: 'setActiveFilterCount', payload: 0 })
                    dispatch({ type: 'setStores', payload: results })
                    dispatch({ type: 'setAllStores', payload: results })
                    dispatch({ type: 'showChangeLocationButton', payload: false })
                    if (results[0]) {
                      dispatch({
                        type: 'setUserLocation',
                        payload: {
                          lat: Number.parseFloat(results[0].latitude),
                          lng: Number.parseFloat(results[0].longitude),
                        },
                      })
                    }
                  })
                }}
                setLoading={() => {
                  dispatch({ type: 'showChangeLocationButton', payload: false })
                }}
                dataCy="map-mobile"
              />
            </FiltersAndSearch>
            <FixedBottomWrapper>
              <UseLocationIconButton
                className={className}
                iconAlt={getMicrocopy({ key: 'storeLocatorMap.UserLocationIconAlt', data })}
                btnAriaLabel={getMicrocopy({ key: 'storeLocatorMap.UserLocationAriaLabel', data })}
                disabled={false}
                handleButtonClick={() => {
                  urlFilter.searchParams.delete('filter')
                  window.history.replaceState(null, '', urlFilter.href)
                  dispatch({ type: 'showChangeLocationButton', payload: false })
                  dispatch({ type: 'clear' })
                  getUserLocation(dispatch, unit, locale)
                }}
                dataCy="map-mobile__"
              />
              <MobileClosedList>
                <DraggableIconButton
                  icon={mobileDraggable}
                  ariaLabel={getMicrocopy({
                    key: 'storeLocatorMap.StoreCardListDraggableAriaLabel',
                    data,
                  })}
                  iconAlt={getMicrocopy({
                    key: 'storeLocatorMap.StoreCardListDraggableAlt',
                    data,
                  })}
                  dataCy="mobile-draggable"
                  onClick={() => setShowStoreCardList(!showStoreCardList)}
                  disabled={stores.storesSet.length === 0}
                />
                {stores.storesSet.length !== 0 ? (
                  <MobileStoreCardClosed
                    storeClick={store => {
                      dispatch({ type: 'setSelectedStore', payload: store })
                      dispatch({ type: 'showStoreCard', payload: !app.showStoreCard })
                      dispatch({ type: 'showChangeLocationButton', payload: false })
                    }}
                    iconAlt={stores.selectedStore.storeType}
                    chevronAlt={getMicrocopy({
                      key: 'storeLocatorMap.StoreCardListChevronAlt',
                      data,
                    })}
                    icon={
                      process.env.GATSBY_BUDDY_PIPELINE === 'uk'
                        ? storeMarker(stores.selectedStore.storeType)
                        : stores.selectedStore.storeTypeIcon
                    }
                    index="selected"
                    address={addressStringMaker(stores.selectedStore.storeAddress)}
                    heading={stores.selectedStore.storeNameExternal}
                    duration={
                      stores.selectedStore.duration
                        ? getWalkingTime(stores.selectedStore.duration.value)
                        : ''
                    }
                    distance={getDistance(stores.selectedStore.distance, unit)}
                    storeType={stores.selectedStore.storeType}
                    walkText={getMicrocopy({
                      key: 'storeLocatorMap.StoreCardWalkText',
                      data,
                    })}
                    store={stores.selectedStore}
                    latitude={stores.selectedStore.latitude}
                    longitude={stores.selectedStore.longitude}
                    showWalkingDistance={location.userLocation !== {}}
                    user={location.userLocation}
                    facilities={stores.selectedStore.storeFacilities}
                    hours={stores.selectedStore.storeOperatingHours}
                    storeTimesFormat={storeTimesFormat}
                    storeOpenText={getMicrocopy({
                      key: 'storeLocatorMap.StoreOpenText',
                      data,
                    })}
                    storeClosedText={getMicrocopy({
                      key: 'storeLocatorMap.StoreClosedText',
                      data,
                    })}
                    opensText={getMicrocopy({
                      key: 'storeLocatorMap.StoreOpensText',
                      data,
                    })}
                    openTomorrowText={getMicrocopy({
                      key: 'storeLocatorMap.StoreOpenTomorrowText',
                      data,
                    })}
                    store24HoursText={getMicrocopy({
                      key: 'storeLocatorMap.Store24HoursText',
                      data,
                    })}
                    storeOpen24HoursText={getMicrocopy({
                      key: 'storeLocatorMap.storeOpen24HoursText',
                      data,
                    })}
                    closesText={getMicrocopy({
                      key: 'storeLocatorMap.StoreClosesText',
                      data,
                    })}
                    mondayText={getMicrocopy({
                      key: 'storeLocatorMap.monday',
                      data,
                    })}
                    tuesdayText={getMicrocopy({
                      key: 'storeLocatorMap.tuesday',
                      data,
                    })}
                    wednesdayText={getMicrocopy({
                      key: 'storeLocatorMap.wednesday',
                      data,
                    })}
                    thursdayText={getMicrocopy({
                      key: 'storeLocatorMap.thursday',
                      data,
                    })}
                    fridayText={getMicrocopy({
                      key: 'storeLocatorMap.friday',
                      data,
                    })}
                    saturdayText={getMicrocopy({
                      key: 'storeLocatorMap.saturday',
                      data,
                    })}
                    sundayText={getMicrocopy({
                      key: 'storeLocatorMap.sunday',
                      data,
                    })}
                    dataCyScreenSize={dataCyScreenSize}
                    type={stores.selectedStore.storeType}
                  />
                ) : (
                  !app.loading && (
                    <StoreCardNoResults
                      dataCy={`store-card-${dataCyScreenSize}`}
                      title={getMicrocopy({ key: 'storeLocatorMap.StoreCardNoResultsTitle', data })}
                      subtitle={getMicrocopy({
                        key: 'storeLocatorMap.StoreCardNoResultsSubTitle',
                        data,
                      })}
                    />
                  )
                )}
              </MobileClosedList>
            </FixedBottomWrapper>
          </>
        )}
      </MobileOverlay>
      {app.showChangeLocationButton &&
        process.env.GATSBY_BUDDY_PIPELINE === 'uk' &&
        !showStoreCardList &&
        !app.showStoreCard &&
        !app.showFilters && (
          <SearchThisAreaStyled
            className={className}
            searchAreaButtonText={getMicrocopy({
              key: 'storeLocatorMap.searchThisAreaText',
              data,
            })}
            onClick={() => {
              urlFilter.searchParams.delete('filter')
              window.history.replaceState(null, '', urlFilter.href)
              dispatch({ type: 'showChangeLocationButton', payload: false })
              dispatch({
                type: 'setUserLocation',
                payload: { lat: location.movedMapLocation.lat, lng: location.movedMapLocation.lng },
              })
              dispatch({
                type: 'setSelectedLocation',
                payload: { lat: location.movedMapLocation.lat, lng: location.movedMapLocation.lng },
              })
              getStores({
                lat: location.movedMapLocation.lat,
                lng: location.movedMapLocation.lng,
                locale,
                unit,
              }).then(results => {
                dispatch({ type: 'clear' })
                dispatch({ type: 'setActiveFilterCount', payload: 0 })
                dispatch({ type: 'setStores', payload: results })
                dispatch({ type: 'setAllStores', payload: results })
              })
            }}
            dataCyScreenSize={dataCyScreenSize}
          />
        )}
      <StoreLocatorMap
        zoom={14}
        mobileMap
        apiKey={process.env.GATSBY_GOOGLE_API_KEY}
        mapCenter={location.selectedLocation}
        stores={stores.storesSet}
        dataCyScreenSize={dataCyScreenSize}
        onDrag={blurSearchInputOnDrag}
      />
      {showStoreCardList && stores.storesSet.length !== 0 && (
        <MobileStoreCardList
          draggableIconAlt={getMicrocopy({
            key: 'storeLocatorMap.StoreCardListDraggableAlt',
            data,
          })}
          draggabAriaLabel={getMicrocopy({
            key: 'storeLocatorMap.StoreCardListDraggableAriaLabel',
            data,
          })}
          locale={locale}
          stores={stores.storesSet}
          className={className}
          showWalkingDistance={location.userLocation !== {}}
          storeOpenText={getMicrocopy({ key: 'storeLocatorMap.StoreOpenText', data })}
          opensText={getMicrocopy({ key: 'storeLocatorMap.StoreOpensText', data })}
          closesText={getMicrocopy({ key: 'storeLocatorMap.StoreClosesText', data })}
          storeClosedText={getMicrocopy({ key: 'storeLocatorMap.StoreClosedText', data })}
          openTomorrowText={getMicrocopy({ key: 'storeLocatorMap.StoreOpenTomorrowText', data })}
          store24HoursText={getMicrocopy({
            key: 'storeLocatorMap.Store24HoursText',
            data,
          })}
          storeTimesFormat={storeTimesFormat}
          storeOpen24HoursText={getMicrocopy({
            key: 'storeLocatorMap.StoreOpen24HoursText',
            data,
          })}
          mondayText={getMicrocopy({
            key: 'storeLocatorMap.monday',
            data,
          })}
          tuesdayText={getMicrocopy({
            key: 'storeLocatorMap.tuesday',
            data,
          })}
          wednesdayText={getMicrocopy({
            key: 'storeLocatorMap.wednesday',
            data,
          })}
          thursdayText={getMicrocopy({
            key: 'storeLocatorMap.thursday',
            data,
          })}
          fridayText={getMicrocopy({
            key: 'storeLocatorMap.friday',
            data,
          })}
          saturdayText={getMicrocopy({
            key: 'storeLocatorMap.saturday',
            data,
          })}
          sundayText={getMicrocopy({
            key: 'storeLocatorMap.sunday',
            data,
          })}
          chevronAlt={getMicrocopy({ key: 'storeLocatorMap.StoreCardListChevronAlt', data })}
          user={location.userLocation}
          unit={unit}
          walkText={getMicrocopy({ key: 'storeLocatorMap.StoreCardWalkText', data })}
          draggable
          draggableClick={() => setShowStoreCardList(!showStoreCardList)}
          storeClick={
            store => {
              setShowStoreCardList(!showStoreCardList)
              dispatch({ type: 'showStoreCard', payload: !app.showStoreCard })
              dispatch({ type: 'setSelectedStore', payload: store })
              dispatch({
                type: 'setSelectedLocation',
                payload: {
                  lat: Number.parseFloat(store.latitude),
                  lng: Number.parseFloat(store.longitude),
                },
              })
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
          dataCyScreenSize={dataCyScreenSize}
        />
      )}
      {app.showFilters && (
        <StoreFilters
          storeTypes={storeTypes}
          clearBtnLabel={getMicrocopy({
            key: 'storeLocatorMap.FiltersClearButtonLabel',
            data,
          })}
          closeBtnLabel={getMicrocopy({
            key: 'storeLocatorMap.FiltersCloseButtonLabel',
            data,
          })}
          storeFiltersHeading={getMicrocopy({
            key: 'storeLocatorMap.FiltersStoreFiltersHeading',
            data,
          })}
          storeFiltersLegend={getMicrocopy({
            key: 'storeLocatorMap.FiltersStoreFiltersLegend',
            data,
          })}
          applyBtnLabel={getMicrocopy({
            key: 'storeLocatorMap.FiltersApplyBtnLabel',
            data,
          })}
          noResults={getMicrocopy({
            key: 'storeLocatorMap.FiltersNoResultText',
            data,
          })}
          handleChecked={() => {}}
          invalid={false}
          country={process.env.GATSBY_BUDDY_PIPELINE || 'uk'}
          handleClear={() => {}}
          handleClose={() => {
            dispatch({ type: 'showFilters', payload: false })
          }}
          handleApplyFilters={() => {}}
          iconAlt="iconalt"
          dataCyScreenSize={dataCyScreenSize}
        />
      )}
      {app.showStoreCard && stores.storesSet.length !== 0 && (
        <StoreCard
          storeOpenText={getMicrocopy({ key: 'storeLocatorMap.StoreOpenText', data })}
          opensText={getMicrocopy({ key: 'storeLocatorMap.StoreOpensText', data })}
          closesText={getMicrocopy({ key: 'storeLocatorMap.StoreClosesText', data })}
          storeClosedText={getMicrocopy({ key: 'storeLocatorMap.StoreClosedText', data })}
          openTomorrowText={getMicrocopy({ key: 'storeLocatorMap.StoreOpenTomorrowText', data })}
          storeOpen24HoursText={getMicrocopy({
            key: 'storeLocatorMap.StoreOpen24HoursText',
            data,
          })}
          store24HoursText={getMicrocopy({
            key: 'storeLocatorMap.Store24HoursText',
            data,
          })}
          crossIconAlt={getMicrocopy({ key: 'storeLocatorMap.StoreCardCrossAlt', data })}
          heading={stores.selectedStore.storeNameExternal}
          icon={
            process.env.GATSBY_BUDDY_PIPELINE === 'uk'
              ? storeMarker(stores.selectedStore.storeType)
              : stores.selectedStore.storeTypeIcon
          }
          iconAlt={stores.selectedStore.storeTypeIconAlt}
          address={addressStringMaker(stores.selectedStore.storeAddress)}
          addressIconAlt={getMicrocopy({ key: 'storeLocatorMap.StoreCardAddressIconAlt', data })}
          showWalkingDistance={location.userLocation !== {}}
          distance={getDistance(stores.selectedStore.distance, unit)}
          distanceIconAlt={getMicrocopy({ key: 'storeLocatorMap.StoreCardDistanceIconAlt', data })}
          user={location.userLocation}
          servicesText={getMicrocopy({ key: 'storeLocatorMap.StoreCardServicesText', data })}
          walkText={getMicrocopy({
            key: 'storeLocatorMap.StoreCardWalkText',
            data,
          })}
          latitude={stores.selectedStore.latitude}
          longitude={stores.selectedStore.longitude}
          duration={
            stores.selectedStore.duration ? getWalkingTime(stores.selectedStore.duration.value) : ''
          }
          facilities={stores.selectedStore.storeFacilities}
          amenitiesText={getMicrocopy({ key: 'storeLocatorMap.StoreCardAmenitiesText', data })}
          storeCardImage={stores.selectedStore.storeCardImage}
          richText={stores.selectedStore.richText}
          directionCTA={{
            name: getMicrocopy({ key: 'storeLocatorMap.StoreCardDirectionsButton', data }),
            link: createDirectionsLink(
              { lat: stores.selectedStore.latitude, lng: stores.selectedStore.longitude },
              { lat: location.userLocation.latitude, lng: location.userLocation.longitude }
            ),
          }}
          orderCTA={{
            name: getMicrocopy({ key: 'storeLocatorMap.StoreCardSecondButtonName', data }),
            link: storeCardSecondButtonLink,
          }}
          backButtonText={getMicrocopy({ key: 'storeLocatorMap.StoreCardBackButtonText', data })}
          backButtonOnClick={() => {
            dispatch({ type: 'showStoreCard', payload: false })
            dispatch({ type: 'showChangeLocationButton', payload: false })
          }}
          storeTimes={stores.selectedStore.storeOperatingHours}
          storeTimesFormat={storeTimesFormat}
          monday={getMicrocopy({ key: 'storeLocatorMap.monday', data })}
          tuesday={getMicrocopy({ key: 'storeLocatorMap.tuesday', data })}
          wednesday={getMicrocopy({ key: 'storeLocatorMap.wednesday', data })}
          thursday={getMicrocopy({ key: 'storeLocatorMap.thursday', data })}
          friday={getMicrocopy({ key: 'storeLocatorMap.friday', data })}
          saturday={getMicrocopy({ key: 'storeLocatorMap.saturday', data })}
          sunday={getMicrocopy({ key: 'storeLocatorMap.sunday', data })}
          dataCyScreenSize={dataCyScreenSize}
          type={stores.selectedStore.storeType}
        />
      )}
    </>
  )
}

const FixedBottomWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`

const MobileOverlay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  z-index: 1;
  height: 100%;
  width: 100%;
`

const DraggableIconButton = styled(IconButton)`
  margin: 16px auto 14px;
  pointer-events: all;
  height: 4px;
  display: flex;
`

const SearchThisAreaStyled = styled(SearchThisAreaContainer)`
  position: absolute;
  right: 0;
  display: flex;
  left: 0;
  top: 74px;
  justify-content: center;
  align-items: center;
`

const MobileStoreCardClosed = styled(StoreCardClosed)`
  position: relative;
  align-self: flex-end;
  bottom: 0;
  pointer-events: all;
`

const MobileStoreCardList = styled(StoreCardList)`
  background-color: ${color.white};
  width: 100%;
  top: 0;
  pointer-events: all;
`

const MobileClosedList = styled.div`
  background: ${color.white};
  display: flex;
  flex-direction: column;
  pointer-events: all;
`

const UseLocationIconButton = styled(UserLocationButton)`
  position: relative;
  align-self: flex-end;
  margin: 0 12px 12px auto;
  pointer-events: all;
`

const FiltersAndSearch = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 1;
  pointer-events: all;
  height: 48px;
  margin: 10px 12px 0;
`

const FilterButton = styled(StoreFiltersClosed)`
  margin-right: 12px;
`

const FullWidthSearchBar = styled(StoreLocatorSearchBar)`
  width: 100%;
`

export default SmallStoreLocatorMapRegion
