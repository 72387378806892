import { useStaticQuery, graphql } from 'gatsby'
import microcopyDefaults from './microcopyDefaults'
import richTextRenderer from './richTextRenderer'

export const getMicrocopy = ({ key, tokens = {}, fallback = null, data = {} }) => {
  if (!data[key]) {
    return key
  }

  const value = data[key]

  const replacements = {}
  Object.keys(tokens).forEach(token => {
    replacements[`{${token}}`] = tokens[token]
  })

  if (value && Object.keys(replacements).length) {
    const re = new RegExp(Object.keys(replacements).join('|'), 'gi')
    const result = value.replace(re, matched => {
      return replacements[matched]
    })

    return result
  }

  if (value) {
    return value
  }

  return fallback
}

export function getMicrocopyObject(microcopy) {
  return Object.entries(microcopy).reduce((acc, [key, value]) => {
    const keys = key.split('.')
    const lastKey = keys.pop()
    // eslint-disable-next-line no-return-assign
    const lastObj = keys.reduce((obj, k) => obj[k] || (obj[k] = {}), acc)
    // or fall back to a default value
    lastObj[lastKey] = value || microcopyDefaults[key]
    return acc
  }, microcopyDefaults)
}

export const useMicrocopy = locale => {
  const { allContentfulMicrocopy } = useStaticQuery(graphql`
    query MicrocopyQuery {
      allContentfulMicrocopy {
        group(field: node_locale) {
          nodes {
            __typename
            key
            value
            richTextValue {
              raw
            }
            locale: node_locale
          }
          fieldValue
        }
      }
    }
  `)

  const microcopyPairs = {}

  allContentfulMicrocopy.group
    .find(nodes => nodes.fieldValue === locale)
    .nodes.forEach(microcopy => {
      // if rich text value is present, render it, otherwise use the plain text value
      microcopyPairs[microcopy.key] = microcopy.richTextValue
        ? richTextRenderer(microcopy.richTextValue)
        : microcopy.value
    })

  return microcopyPairs
}
