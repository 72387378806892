import React from 'react'
import styled from 'styled-components'

// TODO: remove hardcoded text
// TODO should render a span not a div as div is not allowed inside a button

function MapLoadingSpinner({ className, text, fixed, customStyles }) {
  return (
    <Spinner
      className={`${(fixed && 'fixed') || ''} ${(customStyles && customStyles) || ''} ${className}`}
    >
      <div className="circle1 circle" />
      <div className="circle2 circle" />
      <div className="circle3 circle" />
      <div className="circle4 circle" />
      <div className="circle5 circle" />
      <div className="circle6 circle" />
      <div className="circle7 circle" />
      <div className="circle8 circle" />
      <div className="circle9 circle" />
      <div className="circle10 circle" />
      <div className="circle11 circle" />
      <div className="circle12 circle" />
      {text && <div className="searchingText">{text}</div>}
    </Spinner>
  )
}

const Spinner = styled.div`
  width: 2rem;
  height: 3rem;
  position: absolute;
  left: 50%;
  margin-top: -1.25rem;
  margin-left: -1.25rem;
  top: 50%;
  z-index: 150;

  .circle {
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: 0;
    top: 0;
  }

  .circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 0.3125rem;
    height: 0.5rem;
    background-color: #89764b;
    border-radius: 50%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }

  .circle.circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .circle.circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  .circle.circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .circle.circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  .circle.circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  .circle.circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .circle.circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  .circle.circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  .circle.circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .circle.circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  .circle.circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  .circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  .circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  .circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%,
    100%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }

  @keyframes sk-circleFadeDelay {
    0%,
    100%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }

  .searchingText {
    position: absolute;
    top: 3.4375rem;
    left: -1rem;
  }

  .fixed {
    top: 20.625rem;
  }
`

export default MapLoadingSpinner

MapLoadingSpinner.defaultProps = {
  text: 'Searching',
  fixed: false,
  customStyles: '',
  className: '',
}
